import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Expert Care for Vomiting: Your Path to Relief</h3>    
    <p className='d-md-none d-lg-none'>Persistent or recurrent vomiting can be distressing, impacting both your physical well-being and quality of life. Seeking the expertise of a specialized doctor is crucial for accurate diagnosis and effective treatment. This service page aims to guide you through the importance of consulting a doctor for vomiting, the potential causes, the diagnostic process, and available treatments.</p>

<p className='mob-noee'>Persistent or recurrent vomiting can be distressing, impacting both your physical well-being and quality of life. Seeking the expertise of a specialized doctor is crucial for accurate diagnosis and effective treatment. This service page aims to guide you through the importance of consulting a doctor for vomiting, the potential causes, the diagnostic process, and available treatments.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
