import React from 'react'
//import { Navbar} from './home_page_v2/Navbar_v2'
import Nav from './home_page_v2/Nav/index'
import Hero from './home_page_v2/Hero/index'
import Footer from './home_page_v2/Footer/index'
import Second_Section from './home_page_v2/Second_Section/index'
import Third_Section from './home_page_v2/Third_Section/index'
import Fourth_Section from './home_page_v2/Fourth_Section/index'
import Fifth_Section from './home_page_v2/Fifth_Section/index'
import Sixth_Section from './home_page_v2/Sixth_Section/index'
import Book_Visit from  './home_page_v2/Book_Visit/index'
import Seventh_Section from './home_page_v2/Seventh_Section/index'
import Eight_Section from './home_page_v2/Eight_Section/index'
// import Video_Testimonial from './home_page_v2/Video_Testimonial/index'
import NInth_Section from './home_page_v2/Ninth_Section/index'
//import { Footer } from './home_page_v2/footer_v2'
// import { Adbanner } from './home_page_v2/adbanner'
// import { Landingpage } from './home_page_v2/headerdesktop'
// import { Ourcommunity } from './home_page_v2/ourcommunity'
// import { Whykyno } from './home_page_v2/whykyno'
import { Helmet } from 'react-helmet'
import logo from "../../src/utilities/images/home_v2/logo.png"
// import {useNavigate} from 'react-router-dom'



export default function Home_New(){

    // const navigate = useNavigate()
    // const servicesClicked =()=>{
    //     const elem = document.getElementById("services");
    //     if(elem){
    //         elem.scrollIntoView({'behavior':"smooth"})
    //     }
    // }
    const homepagescheme = 
{
  "@context": "https://schema.org",
  "@type": "PrimaryCare",
  "name": "KynoHealth",
  "image": "https://www.kynohealth.com/images/Kyno-footer-logo.png",
  "@id": "https://www.kynohealth.com/",
  "url": "https://www.kynohealth.com/",
  "telephone": "9355618123",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL",
    "addressLocality": "Gurgaon",
    "postalCode": "122001",
    "addressCountry": "IN"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 28.4253544,
    "longitude": 77.06304709999999
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/KynoHealth-106490852367301",
    "https://twitter.com/Kynohealth",
    "https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y=",
    "https://youtube.com/@kynohealth",
    "https://www.linkedin.com/company/kyno-health/",
    "https://in.pinterest.com/kynohealth/",
    "https://www.tumblr.com/kynohealth",
    "https://www.kynohealth.com/"
  ] 
}



    return(
        
        <div>
            <script type="application/ld+json">
        {JSON.stringify(homepagescheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Medical Consultation | Best Physician Doctor in Noida</title>
                    <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment."/>
                    <link rel="canonical" href="https://www.kynohealth.com/"/>

                    <meta property='og:image'  content={logo} />
                    <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
            </Helmet>
            <Nav/>
            <Hero/> 
            <Second_Section/>
            <Third_Section/>
            <Fourth_Section/>
            <Fifth_Section/>
            <Book_Visit />
            <Sixth_Section/>
            <Seventh_Section/>
            <Eight_Section/>
           {/* <Video_Testimonial/> */}
            <NInth_Section/>
    

    <Footer/>


            {/*<Landingpage />
            <Ourcommunity />
            <Adbanner />
            <Whykyno />
    <Footer />*/}
        </div>
    )
}