import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Comprehensive Care for Weakness: Seeking a Doctor's Expertise 
</h3>
    
    <p className='d-md-none d-lg-none'>Experiencing weakness can significantly impact your quality of life, hindering your ability to perform daily tasks and enjoy activities you once loved. Whether you're grappling with unexplained fatigue, muscle weakness, or a general lack of energy, seeking the guidance of a qualified healthcare professional is crucial.</p>

<p className='mob-noee'>This comprehensive service page aims to provide insights into the importance of consulting a doctor for weakness, the potential causes, the diagnostic process, and available treatments.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
