import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>In-Home IV Drip Services for Enhanced Wellness</h3>    
    <p className='d-md-none d-lg-none'>At Kyno Health Center, we recognize the importance of personalized healthcare that meets the unique needs and lifestyles of our clients. Our In-Home IV Drip Services bring the benefits of intravenous therapy directly to your doorstep, ensuring a convenient and comfortable experience that promotes optimal wellness.</p>

<p className='mob-noee'>At Kyno Health Center, we recognize the importance of personalized healthcare that meets the unique needs and lifestyles of our clients. Our In-Home IV Drip Services bring the benefits of intravenous therapy directly to your doorstep, ensuring a convenient and comfortable experience that promotes optimal wellness.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
