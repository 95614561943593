import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Tips For Finding The Best Home Visit Doctors</h3>    
    <p className='d-md-none d-lg-none'>Are you struggling to find potential home visit <a href="https://www.kynohealth.com/doctors-near-me"><strong>doctors near me</strong></a> on the web to manage your health complications? Though the internet is a helpful place to look for doctors, following certain tips can make your search fruitful. Choosing the right doctor can turn into a stressful task if you fail to evaluate their potential based on certain parameters. 
    <br/>
    Well, with so many affiliated visit-home doctors practicing in town, you might face major confusion in selecting a medical personality. However, various tips can help you get in touch with the appropriate home doctor efficiently.
    </p>


<p className='mob-noee'>Are you struggling to find potential home visit <a href="https://www.kynohealth.com/doctors-near-me"><strong>doctors near me</strong></a> on the web to manage your health complications? Though the internet is a helpful place to look for doctors, following certain tips can make your search fruitful. Choosing the right doctor can turn into a stressful task if you fail to evaluate their potential based on certain parameters. 
    <br/>
    Well, with so many affiliated visit-home doctors practicing in town, you might face major confusion in selecting a medical personality. However, various tips can help you get in touch with the appropriate home doctor efficiently. 
    </p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
