import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Navbar from "../homePage/Navbar"
import "../../styles/disease.css"

import img from "../../utilities/images/bookingConfirmed.png"
import Footer from '../homePage/footer'

import axios from 'axios'
import { backendURL } from '../../utilities/constants'


import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'


export default function Disease(props) {

    // const {id} = useParams()
    // console.log(id)

    const [qp] = useSearchParams()
    // console.log(qp.get("seoId"))

    const [seoId, setSeoId] = useState(qp.get("id"))
    const [isLoaded, setIsLoaded] = useState(false)
    const [seoObj, setSeoObj] = useState(null)

    const [docObj, setDocObj] = useState(null)
    const [docLoading, setDocLoading] = useState(false)

    const fetchSeoContent = () => {
        console.log("Fetching seo data with seo id ", seoId)
        axios.get(`${backendURL}seo?seoId=${seoId}`).then((resp) => {
            let r = resp.data.data
            setSeoObj(r)
            setIsLoaded(true)
            document.title = `Kyno Health | ${r.page_heading.title}`
        }).catch(err => {
            console.log(err)
            setIsLoaded(false)
        })
    }

    const fetchDoctors = () => {
        axios.get(`${backendURL}allDoctors`).then((resp) => {
            let r = resp.data.data;
            console.log(resp.data.data, seoObj)
            setDocObj(r);
            setDocLoading(true)
        }).catch(err => {
            console.log(err)
            setDocLoading(false)
        })
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 100 },
            items: 1,
        },
        // tablet: {
        //     breakpoint: { amx: 768, min: 500 },
        //     item: 2,
        // }
    };


    useEffect(() => {
        fetchSeoContent()
        fetchDoctors()
    }, [])


    return (
        <>
            <div>
                <Navbar Source="Seo Page"></Navbar>

                <div className='whole-block'>
                    <div className='left-block'>

                        {isLoaded == false && seoObj == null ? (
                            <div>
                                Loading Content
                            </div>
                        ) : (
                            <div>
                                <div className='page-heading'>
                                    <div>
                                        {seoObj.page_heading.title}
                                    </div>
                                    <img src={img} />
                                </div>

                                <div className='sub-headings'>
                                    {seoObj.sub_headings.map((block, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='heading'> {block.title} </div>
                                                <div className='content'> {block.content} </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        )}


                    </div>
                    <div className='right-block sticky'>
                        <div className='form'>

                            <div className='heading'>Book Appointment</div>
                            <div className='name-input'>
                                <div>Name</div>
                                <input type='text' placeholder='Name' />
                            </div>
                            <div className='phone-input'>
                                <div>Phone Number</div>
                                <input type='number' placeholder='Phone Number' />
                            </div>
                            <div className='concern-input'>
                                <div>Concern</div>
                                <input type='text-box' placeholder='Concern'></input>
                            </div>
                            <div className='submit-btn'>Submit</div>
                        </div>


                        <div className='doctors'>

                            <div className='title'>
                                Our Doctors
                            </div>
                            {docLoading !== false && docObj !== null &&
                                <Carousel responsive={responsive} arrows={false} showDots={false} swipeable={true} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
                                    {docObj.map((block, index) => {
                                        return (
                                            <div className='_doc' key={index}>
                                                <img src={`https://drive.google.com/uc?export=view&id=${block.img_link}`} alt={block.name} />
                                                <div className='name'>{block.name}</div>
                                                <div className='speciality'>{block.speciality}</div>
                                                <div className='experience'>{block.experience}</div>
                                            </div>

                                        )
                                    })}

                                </Carousel>
                            }
                        </div>

                    </div>
                </div>

                <Footer phone_number={"9953104104"} />
            </div>
        </>
    )
}