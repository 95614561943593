import React from 'react'
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (
    <>
<section className="seven-fold">
  <div className="container">
  <div className="gradss">
<Image
      src={require('../Performance/images/select-Gradient.png')}
      webp={require('../Performance/images/select-Gradient.webp')}
      alt='gradientImg'
    />
  <img src="images/select-Gradient.png" alt='select' />
  </div>

  <h5>Our Various Services</h5>
 
  <div className='seven-l-side ml-70'>
    
    <h2>Compassionate Care <br/>at Your Fingertips</h2>
   
  
  </div>  

  <div className='home-visit'>
  <div className='row row d-flex align-items-center'>
<div className='col-md-9 col-7'>
<h3>General Physician  Home visit</h3>
<p className='d-none'>For all your multiple issues,  all in just 1 visit altogether.</p>
<p>For all your multiple issues, 
Resolve them all in just 1 visit.</p>
{/*<p className='mrm-btm'><span>Book a visit <img src='images/Arrow-Right.svg'/> </span></p>*/}
<div className='bookslot'>
<p className='btn btn-warning'><span><a href="tel:+919953104104">Call Us</a> </span></p>
  </div>
</div>

  <div className='col-md-3 col-5'>
  <div className='seven-r-side'>
<img src='images/home-girl.svg' alt='home' />

  </div>

  </div>

  </div>

  </div>

  <div className='thrs-part'>
  <div className='row'>

<div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>Nursing <br/> Care </h4>
<Image
      src={require('../Performance/images/Sanitizer.png')}
      webp={require('../Performance/images/Sanitizer.webp')}
      alt='SanitizerImg'
    />
<img src='images/Sanitizer.png' alt='sanitizer' />
  </div></div>

 
  <div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>Diagnostics  <br/> support at home </h4>
<img src='images/Vaccine.svg' alt='vaccine' />
  </div></div>

  <div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>ECG / X- Ray <br/> at home</h4>
<img src='images/Lungs-Infecion.svg' alt='lungs' />
  </div></div>

  <div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>Ambulance  <br/>  Assistance</h4>
<img src='images/trained.svg' alt='trained' />
  </div></div>

  <div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>Physiotherapy <br/> at Home</h4>  
<img src='images/ambulance.svg' alt='ambulance' />
  </div></div>

  <div className='col-md-4 col-6'>
<div className='thrs-one'>
<h4>24-Hour <br/> Caregivers</h4>
<img src='images/need.svg' alt='need' />
  </div></div>

  

  </div>

{/* <div className='all text-center'>
  <a className="btn btn-warning" href="#">View all</a></div> */}

  </div>

  
  </div>
  </section>
    </>
  )
}

export default index