import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Fever Doctor Services: Expert Care for Elevated Temperatures</h3>
    
    <p className='d-md-none d-lg-none'>Fever is often an indication that your body is fighting off an infection or illness. When fever strikes, it's essential to seek professional medical guidance to ensure a proper diagnosis and effective treatment. Our "Fever Doctor" services offer you access to experienced healthcare professionals who specialize in fever management, helping you navigate your health with confidence and care.
</p>

<p className='mob-noee'>Fever is often an indication that your body is fighting off an infection or illness. When fever strikes, it's essential to seek professional medical guidance to ensure a proper diagnosis and effective treatment. Our "Fever Doctor" services offer you access to experienced healthcare professionals who specialize in fever management, helping you navigate your health with confidence and care.
</p>
 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
