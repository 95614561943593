import React, { useEffect, useState } from 'react'
import "../../styles/ad_cms.scss"
import logo from "../../utilities/images/logo.png"
import oc1 from '../../utilities/images/overview.png'
import banner from "../../utilities/images/ad_cms/heading_frame.png"
import medicalEquipment from "../../utilities/images/medicalEquipment.png"
import Footer from '../homePage/footer'
import FAQ from '../homePage/faq'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { backendURL, localBackend } from '../../utilities/constants'
import greenTick from "../../utilities/images/greenTick.png"
import Meetoutdoctors from '../homePage/meetOurdoctors'
import { Helmet } from 'react-helmet'
import Popup from 'reactjs-popup'

export default function Ad_CMS() {


    const navigate = useNavigate()

    const [sp] = useSearchParams()
    const searchParams = sp.get("id")
    const pageId = useParams().id;

    const id = searchParams || pageId
    console.log(pageId, searchParams, id)

    const [pageContent, setPageContent] = useState(null)
    const [localities, setLocalities] = useState(null)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState()
    const [selectedLocality, setSelectedLocality] = useState("")
    const [msg, setMsg] = useState("")

    const fetchPageContent = async () => {
        const result = await axios.get(`${backendURL}getDiseaseLandingPage?id=${id}`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setPageContent(resp.data.data)
                console.log(resp.data.data)
            }
        })
    }

    const openInNewTab = (url) => {
        window.open(url, '_black', 'noreferrrer')
    }

    const fetchLocalities = async () => {
        const res = await axios.get(`${backendURL}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                console.log("Localities: ", resp.data.data)
                return resp.data.data
            }
        })
    }

    const onSubmitClick = async () => {
        // console.log(name, phone,selectedLocality)
        const r = validatePhone(phone)
        if (r === 'TRUE') {

            let body = {
                "name": name,
                "phone": phone,
                "source": "Google Ad Page",
                "concern": pageContent.concern
            }
            const result = await axios.post(`${backendURL}createLead`, body).then((resp) => {
                if (resp.data.statusCode === 1) {
                    navigate('/bookingConfirmed')
                } else {
                    setMsg("Something Went Wrong!")
                }
            }).catch((err) => {
                setMsg("Something Went Wrong!")
            })
        }
    }

    const gtag_report_conversion = (url) => {
        console.log(url.href)
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        }

        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/KBFFCOiLhpEYEKGEvaUp',
            'event_callback': callback
        });

        return false;

    }

    const gtag_report_conversion_wa = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/WTK8CKiy0ZMYEKGEvaUp',
            'event_callback': callback
        });
        return false;
    }


    function gtag() {
        window.dataLayer.push(arguments);
    }

    const validatePhone = (phoneNo) => {
        var plen = Math.log(phoneNo) * Math.LOG10E + 1 | 0;
        if (plen !== 10) {
            setMsg("Enter a 10-digit number")
            return "LENGTH-ERR"
        }

        var msb = Math.trunc(phoneNo / 1000000000)

        if (msb < 6 || msb > 9) {
            setMsg("Phone Number Invalid")
            return "MSB-ERR"
        }

        setMsg("")
        return "TRUE"
    }

    useEffect(() => {
        fetchPageContent()
        // fetchLocalities()
        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', 'AW-11084448289')
        gtag('config', 'G-96HFKDRG6C');
    }, [])

    return (
        <>
            <div className='ad-cms'>
                <Helmet>
                    <script>
                        {`
                        (function(h,o,t,j,a,r){
                            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                        h._hjSettings={hjid:3541185,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
                    </script>
                </Helmet>
                <div className='title'>
                    <div className='logo'>
                        <img src={logo} alt='Kyno logo' />
                    </div>
                    <div className='call-btn' onClick={() => {
                        gtag_report_conversion(window.location);
                        openInNewTab("tel:+917996643888")
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#068c69"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
                        Call Us
                    </div>
                </div>

                {pageContent === null ? (
                    <div>Loading Content</div>
                ) : (
                    <div>

                        <div className='main-banner'>
                            <img className='banner' src={banner} alt="banner" />
                            <img className='oc1' src={oc1} alt="oc1" />
                            <div className='content'>
                                <div className='title'>{pageContent.bannerHeading}</div>
                                <div className='advert'>Doctor Available  in 60 Minutes <br />
                                    <span> 24x7
                                        {/* <img src={medicalEquipment}/> */}
                                    </span>
                                </div>
                                <div className='advert-points'>

                                    {pageContent.bannerPoints.map((point, index) => {
                                        return (
                                            <div className='point' key={index}> <span className='radio'></span>{point}</div>
                                        )
                                    })}

                                    {/* <div className='point'> <span className='radio'></span>Reliable Doctors</div>
                                    <div className='point'> <span className='radio'></span>No need to travel and wait for your turn</div>
                                    <div className='point'> <span className='radio'></span>Complete care in home environment</div> */}
                                </div>

                            </div>
                        </div>

                        {/*<div className='price-banner'>
                            <div>Book your visit for just </div>
                            <div>&#8377;999/-</div>
                        </div>*/}
                        {/* <div className='doc-availability'>Doctor Available 24X7</div> */}
                        <div className='cta-form'>
                            {/* <div className='cta' onClick={() => {
                                gtag_report_conversion(window.location);
                                openInNewTab("tel:+917996643888")
                            }}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#068c69"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Now to Book Consultation at Home <br />
                                </span><span>7996 35 35 31</span>
                            </div> */}
                            {/* <div className='or'>or</div> */}
                            <div className='request-callback'>Get a callback right away</div>
                            {/* <input type='text' placeholder='Your Name' value={name} onChange={(e) => {
                                setName(e.target.value)
                            }} /> */}
                            <input type='number' placeholder='Enter Your Phone Number' value={phone} onChange={(e) => {
                                setPhone(e.target.value)
                            }} />
                            {/* {localities !== null &&
                                <select className='locality-select' value={selectedLocality} onChange={(e) => {
                                    setSelectedLocality(e.target.value)
                                }}>
                                    <option key="-1" value="">Select City</option>
                                    {localities.map((block, index) => (
                                        <option key={index} value={block.name}>{block.name}</option>
                                    ))}
                                </select>
                            } */}
                            <div className='submit' onClick={() => {
                                onSubmitClick()
                            }}>Get a Callback</div>

                            {msg !== "" &&
                                <div className='msg'>{msg}</div>
                            }
                        </div>

                        <div className='numbers'>
                            <div className='consultations'>
                                <div>1500+</div>
                                <div>Home Consultations</div>
                            </div>
                            <div className='doctors'>
                                <div>10+</div>
                                <div>Doctors</div>
                            </div>
                            <div className='google'>
                                <div>100%</div>
                                <div>Satisfaction  Rate</div>
                            </div>
                        </div>

                        <div className='sub-headings'>

                            {pageContent.subHeadings.map((subHead, index) => {
                                return (
                                    <div className='sub-heading' key={index}>
                                        <div className='heading-title'>{subHead.title}</div>
                                        {subHead.points.map((point, index2) => {
                                            if (point !== "") {

                                                return (
                                                    <div key={index2} className='heading-point'><img src={greenTick} />{point}</div>
                                                )
                                            }
                                        })}
                                    </div>
                                )
                            })}
                        </div>

                        <Meetoutdoctors />

                        <div className='cta-buttons'>
                            {/* <div className='cta-call' onClick={() => {
                                gtag_report_conversion(window.location);
                                openInNewTab("tel:+917996643888")
                            }}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#068c69"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Now to Book Consultation at Home <br />
                                </span><span>7996 35 35 31</span>
                            </div>

                            <div className='cta-whatsapp' onClick={() => {
                                gtag_report_conversion_wa(window.location)
                                openInNewTab("https://wa.me/9953104104")
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 640 640"><path d="M546.704 91.89C486.526 31.584 406.482-1.582 321.229-1.582 145.609-1.583 2.67 141.368 2.67 317.118c0 56.139 14.705 111.05 42.567 159.297L.001 641.595l168.959-44.34c46.595 25.382 99.013 38.835 152.222 38.835h.13C496.944 636.09 640 493.14 640 317.401c0-85.182-33.166-165.179-93.344-225.463l.047-.047zM321.323 582.315c-47.599 0-94.218-12.827-134.895-36.957l-9.697-5.788-100.265 26.257 26.776-97.726-6.272-10.04C70.312 415.965 56.4 367.244 56.4 317.13c0-146.082 118.832-264.96 265.066-264.96 70.713 0 137.328 27.65 187.302 77.622 49.996 50.127 77.493 116.588 77.493 187.42-.118 146.187-118.95 265.066-264.96 265.066l.024.036zM466.541 383.85c-7.913-4.028-47.115-23.233-54.39-25.89-7.276-2.658-12.58-4.028-17.977 4.027-5.268 7.914-20.587 25.89-25.252 31.265-4.666 5.28-9.284 6.035-17.197 2.008-7.914-4.028-33.674-12.426-64.064-39.568-23.634-21.095-39.662-47.221-44.328-55.134-4.665-7.914-.52-12.308 3.532-16.193 3.661-3.544 7.925-9.284 11.941-13.95 4.028-4.665 5.28-7.925 7.925-13.31 2.658-5.28 1.359-9.946-.637-13.95-2.008-4.015-17.977-43.217-24.485-59.185-6.39-15.603-13.063-13.43-17.965-13.701-4.665-.237-9.945-.237-15.2-.237-5.257 0-13.95 1.996-21.225 9.933-7.276 7.914-27.898 27.26-27.898 66.45 0 39.201 28.512 77.009 32.516 82.407 4.027 5.267 56.162 85.784 136.029 120.238 18.98 8.161 33.803 13.063 45.355 16.854 19.098 6.024 36.425 5.15 50.126 3.13 15.32-2.256 47.115-19.229 53.788-37.831 6.662-18.615 6.662-34.536 4.666-37.831-1.89-3.544-7.158-5.504-15.201-9.58l-.06.048z" /></svg>
                                Chat on Whatsapp
                            </div> */}

                            <div className='request-callback-1'>Need Help ?</div>
                            <div className='request-callback'>Get a callback ASAP</div>
                            <input type='number' placeholder='Enter Your Phone Number' value={phone} onChange={(e) => {
                                setPhone(e.target.value)
                            }} />
                            <div className='submit' onClick={() => {
                                onSubmitClick()
                            }}>Get a Callback</div>
                            {msg !== "" &&
                                <div className='msg'>{msg}</div>
                            }
                        </div>

                        <div className='testimonial-faq'>
                            <div className='tmonial'>
                                <div className='tmonial-title'>
                                    Patient Testimonial
                                </div>
                                <div className='tmonial-video'>
                                    <iframe src="https://www.youtube.com/embed/nxUA1_VowHE"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    />
                                </div>
                            </div>

                            <div className='faq' >
                                <FAQ faqCount={5} />
                            </div>
                        </div>
                    </div>
                )}


                <Footer className="ad-cms-footer" phone_number={"7996643888"} />
                
                <div className='sticky-bottom'>
                    <Popup trigger={
                            <div onClick={() => {
                                gtag_report_conversion(window.location)}}>Book Appointment</div>
                        } modal nested >{
                                (close) => (
                                    <div className='modal'>
                                        <div className='content'>
                                            <div className='title'>Book Appointment</div>
                                            <div className='name-input'>
                                                <div>Name</div>
                                                <input type="text" placeholder='Enter Your Name' value={name} onChange={(e) => {
                                                    setName(e.target.value)
                                                }} />
                                            </div>
                                            <div className='phone-input'>
                                                <div>Phone Number</div>
                                                <input type="number" placeholder='Enter Your Number' value={phone}  onChange={(e) => {
                                                    setPhone(e.target.value)
                                                }} />
                                                {msg !== "" &&
                                                    <div className='msg'>{msg}</div>
                                                }

                                                
                                                {/*{phoneErr !== "" &&
                                                    <div className='phone-err'>
                                                        {phoneErr}
                                                    </div>
                                                }*/}
                                            </div>
                                            {/*<div className='concern-input'>
                                                <div>Concern</div>
                                                <input type="text" onChange={(e) => {
                                                    setConcern(e.target.value)
                                                }} />
                                            </div>*/}
                                            {/* <div className='time-input'>
                                        <div>Expected Time</div>
                                        <input type="time" onChange={(e) => {
                                            setTime(e.target.value)
                                        }} />
                                    </div> */}
                                            <div className='submit' onClick={() => {
                                                const res = onSubmitClick()
                                                if (res === false) {
                                                    close()
                                                }
                                            }}>Submit</div>

                                        </div>
                                        {/* <div>
                                    <button onClick=
                                        {() => close()}>
                                        Close modal
                                    </button>
                                </div> */}
                                    </div>
                                )
                            }</Popup>
                    {/* <div onClick={() => {
                        gtag_report_conversion_wa(window.location)
                        openInNewTab("https://tinyurl.com/2ek8y33y")
                    }}>Connect on WhatsApp</div> */}
                    {/*<div className='phone' onClick={() => {
                        gtag_report_conversion(window.location);
                        openInNewTab("tel:+917996643888")
                    }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Us</div>*/}
                </div>
            </div>
        </>
    )
}
