import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Your Partner in Hypertension Care - Expert Doctors for Effective Management</h3>    
    <p className='d-md-none d-lg-none'>Hypertension, often referred to as high blood pressure, is a pervasive and silent health condition affecting millions of people worldwide. While it may not always exhibit immediate symptoms, the long-term consequences of uncontrolled hypertension can be severe. At Kyno Health clinic, we specialize in hypertension management, offering expert care to help you take control of your blood pressure and reduce the risks associated with this condition.</p>

<p className='mob-noee'>Hypertension, often referred to as high blood pressure, is a pervasive and silent health condition affecting millions of people worldwide. While it may not always exhibit immediate symptoms, the long-term consequences of uncontrolled hypertension can be severe. At Kyno Health clinic, we specialize in hypertension management, offering expert care to help you take control of your blood pressure and reduce the risks associated with this condition.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
