import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Top 10 Benefits of Home Visit Doctors</h3>    
    <p className='d-md-none d-lg-none'>With the revolution of the medical industry, the role of <a href="https://www.kynohealth.com/doctors-near-me"><strong>Home Visit Doctors</strong></a> has significantly replaced the medical treatments performed in traditional settings. Often, it becomes a hectic task to visit the clinic or hospital for treatment due to various inconveniences such as long waiting queues, increased expenses, infection risks, etc. Hence, for a safe and secure treatment at the convenience of your house, you can resort to calling a home visit doctor. There are multiple prominent benefits of why you should call a home visit medical professional for your treatment. </p>

<p className='mob-noee'>With the revolution of the medical industry, the role of <a href="https://www.kynohealth.com/doctors-near-me"><strong>Home Visit Doctors</strong></a> has significantly replaced the medical treatments performed in traditional settings. Often, it becomes a hectic task to visit the clinic or hospital for treatment due to various inconveniences such as long waiting queues, increased expenses, infection risks, etc. Hence, for a safe and secure treatment at the convenience of your house, you can resort to calling a home visit doctor. There are multiple prominent benefits of why you should call a home visit medical professional for your treatment.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
