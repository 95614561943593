import React, {useState} from 'react' 
import "../styles/tnc.scss"

import logo from "../utilities/images/logo.png"
import { useNavigate } from 'react-router-dom'
export default function TNC(){

    const navigate = useNavigate()
 
    return(
        <>
            <div className='tnc'>

                <div className='logo'>
                    <img src={logo} alt="Logo" onClick={(e) => {
                        navigate("/")
                    }} />
                </div>

                <div className='title'>
                Terms and Conditions
                </div>

                <div className='content'>
                    <div className='subtitle'>Thank you for choosing Kyno Health, a division of Huami Technologies Pvt. Ltd. We are dedicated to providing convenient and reliable doctor consultation services at home. These Terms and Conditions govern your use of the Kyno Health website and services. Please read these terms carefully before using our platform.</div>

                    <div className='point'>
                        <div className='point-title'>Nature and Applicability of Terms:</div>
                        <div className='point-content'>1.1 These Terms and Conditions (the "Agreement") constitute a legally binding agreement between Kyno Health and the users of our website and services.</div>
                    </div>

                    <div className='point'>
                        <div className='point-title'>Conditions of Use:</div>
                        <div className='point-content'>2.1 By accessing or using the Kyno Health website or services, you acknowledge that you are at least 18 years of age and agree to comply with these Terms and Conditions.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>End-User Account and Data Privacy:</div>
                        <div className='point-content'>3.1 In order to access certain features of our website and services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for any activities that occur under your account.</div>
                        <div className='point-content'>3.2 We respect your privacy and handle your personal data in accordance with our Privacy Policy. By using our website and services, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Listing Content and Disseminating Information:</div>
                        <div className='point-content'>4.1 Kyno Health strives to provide accurate and up-to-date information on our website. However, we do not guarantee the accuracy, completeness, or reliability of any content or information provided.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Booking Appointments, Diagnostic Tests, Ordering Medicines, and Interaction with Medical Service Providers:</div>
                        <div className='point-content'>5.1 Kyno Health aims to facilitate appointments with medical service providers based on user requests. While we make efforts to ensure confirmed appointments, we cannot guarantee the availability of medical service providers or their ability to meet specific requirements.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'></div>
                        <div className='point-content'></div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>No Doctor-Patient Relationship for Emergency Use:</div>
                        <div className='point-content'>6.1 Kyno Health does not establish a doctor-patient relationship in case of emergency situations. If you require immediate medical attention, please contact emergency services or visit the nearest healthcare facility.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Content Ownership and Copyright Conditions of Access:</div>
                        <div className='point-content'>7.1 The content on the Kyno Health website, including text, graphics, logos, images, and software, is the property of Huami Technologies Pvt. Ltd. or its licensors and is protected by copyright laws.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Reviews and Feedback:</div>
                        <div className='point-content'>8.1 Users may have the opportunity to provide reviews and feedback on our website. By submitting reviews or feedback, you grant Kyno Health a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, translate, distribute, and display the content.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Rights and Obligations Relating to Content:</div>
                        <div className='point-content'>9.1 You are solely responsible for the content you submit or post on the Kyno Health website. By posting content, you represent that you have the necessary rights to distribute the content and that it does not violate any laws or infringe upon the rights of third parties.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Intellectual Property Rights:</div>
                        <div className='point-content'>10.1 Kyno Health and its licensors retain all intellectual property rights in the website, services, and associated content. You agree not to copy, modify, distribute, sell, or lease any part of our platform or services without prior written consent.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Disclaimer of Warranties:</div>
                        <div className='point-content'>11.1 Kyno Health provides its website and services on an "as is" and "as available" basis. We make no warranties or representations regarding the accuracy, reliability, or availability of our platform. Your use of our website and services is at your own risk.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Limitation of Liability:</div>
                        <div className='point-content'>12.1 In no event shall Kyno Health be liable for any indirect, consequential, incidental, special, or punitive damages arising out of or related to your use of our website and services.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Indemnity:</div>
                        <div className='point-content'>13.1 You agree to indemnify, defend, and hold Kyno Health harmless from any claims, damages, losses, liabilities, and expenses, including legal fees, arising out of your use of our website and services or any violation of these Terms and Conditions.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Applicable Law and Dispute Settlement:</div>
                        <div className='point-content'>14.1 These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Contact Information - Grievance Officer:</div>
                        <div className='point-content'>15.1 For any queries or grievances regarding these Terms and Conditions, please contact our Grievance Officer at the following details:</div>
                        <div className='point-content'>Phone: +91-9953104104</div>
                        <div className='point-content'>Email: support@kynohealth.com</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Severability:</div>
                        <div className='point-content'>16.1 If any provision of this Agreement is deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Waiver:</div>
                        <div className='point-content'>17.1 No provision of this Agreement shall be deemed waived unless it is in writing and signed by Kyno Health.</div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>Contact Clause:</div>
                        <div className='point-content'>18.1 By sharing your contact information with Kyno Health, you authorize us to contact you for service-related communications.</div>
                    </div>

                    <h3>By using the Kyno Health website and services, you agree to comply with these Terms and Conditions. If you do not agree with any part of this Agreement, please refrain from using our platform.</h3>
                </div>
            </div>
        </>
    )
}
