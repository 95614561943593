
import './Css/style.css'

function index() {
  return (
    <>

      <section className='ms'>
        <div className='container'>

          <h3>Kyno Privacy Policy Page</h3>
          <p>Huami Technologies Private Limited, also known as "we," "us," or "Kyno Health," is the author and publisher of the website https://www.kynohealth.com/ and its subdomains (collectively referred to as "Websites") on the internet. This also includes other software and applications provided by Kyno Health, such as mobile applications (referred to as "App" and collectively with the Websites as "Services"). Kyno Health offers these services in partnership with its agents, affiliates, associates, representatives, or other third parties (collectively called "Partners").</p>

          <p>This privacy policy ("Privacy Policy") outlines how we collect, use, share, and safeguard personal information from users of our Services (collectively referred to as "you," "User," or "Users" in this Privacy Policy). We developed this Privacy Policy to underscore our commitment to protecting your privacy and personal information. Your access to and use of the Services are governed by this Privacy Policy and our Terms of Use. Any capitalized terms not defined in this Privacy Policy will have the meanings assigned to them in our Terms of Use.</p>

          <p>By confirming that you agree to this Privacy Policy (through the means provided on this website or app), using the Services, or providing us with your information, you consent to the practices and policies outlined in this Privacy Policy. You also consent to our collection, use, and sharing of your information as described in this Privacy Policy. We reserve the right to change, modify, add, or delete portions of this Privacy Policy at our sole discretion, at any time. If you do not agree with this Privacy Policy at any time, please do not use any of the Services or provide us with any of your information. If you use the Services on behalf of someone else (such as your child) or an entity, you represent that you are authorized by that individual or entity to accept this Privacy Policy on their behalf.</p>

          <h4>1. WHY IS THIS PRIVACY POLICY?</h4>
          <p>This Privacy Policy is published in compliance with, inter alia:</p>
          <ul>
            <li>Section 43A of the Information Technology Act, 2000;</li>
            <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the &ldquo;SPI Rules&rdquo;); and</li>
            <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
          </ul>

          <p><strong>This Privacy Policy states the following:</strong></p>
          <ul>
            <li>The type of information collected from the Users, including sensitive personal data or information;</li>
            <li>The purpose, means, and modes of usage of such information; and</li>
            <li>How and to whom we will disclose such information.</li>
          </ul>

          <h4>2. COLLECTION OF PERSONAL INFORMATION</h4>
          <p>Some of our services require us to know your identity to better cater to your needs. When you use these Services, we may ask you to voluntarily provide certain information that personally identifies you or could be used to identify you. This information may include, but is not limited to, the following:</p>

          <ul>
            <li>Contact data (such as your email address and phone number);</li>
            <li>Demographic data (such as your gender, your date of birth, and your pin code); </li>
            <li>Data regarding your usage of the services and history of the appointments and other transactions made by or with you through the use of Services;</li>
            <li>Health or medical data (such as your past medical history and conditions, diagnostic reports, prescriptions, and medication history)</li>
            <li>Insurance data (such as your insurance carrier and insurance plan) and</li>
            <li>Other information that you voluntarily choose to provide to us (such as information shared by you with us through emails or letters, your work details, your family details)</li>
          </ul>

          <p>The information Kyno Health collects from you may be classified as 'personal information' or 'sensitive personal data or information' according to the SPI Rules. Under these rules, personal information is defined as any data related to an individual that can identify them, either on its own or when combined with other information that a corporate body has or is likely to have.</p>

          <p>The SPI Rules further define &ldquo;sensitive personal data or information&rdquo; of a person to mean personal information about that person relating to:</p>

          <ul>
            <li>passwords;</li>
            <li>financial information such as bank accounts, credit and debit card details, or other payment instrument details;</li>
            <li>physical, physiological, and mental health condition;</li>
            <li>sexual orientation;</li>
            <li>medical records and history;</li>
            <li>biometric information;</li>
            <li>information received by body corporate under lawful contract or otherwise;</li>
            <li>visitor details as provided at the time of registration or thereafter; and</li>
            <li>Call data records</li>
          </ul>

          <p>Information that is freely available in the public domain or accessible under the Right to Information Act, 2005 or any other law will not be regarded as personal information or sensitive personal data or information.</p>

          <h4>3. PRIVACY STATEMENTS</h4>
          <ul>
            <li>By using and accessing the Services, each User agrees to comply with the Terms of Use, which includes acceptance of this Privacy Policy. Users who do not agree with any provisions herein may choose to cease using Kyno Health's Services immediately.</li>
            <li>All information, including sensitive personal data, provided by a User to us is voluntary. You acknowledge that Kyno Health, either independently or with its Partners, may utilize such information categorized as 'sensitive personal data or information' under the SPI Rules: (a) to provide you with the Services, (b) for commercial purposes, and in an aggregated or non-personally identifiable format for research, statistical analysis, and business intelligence purposes, and (c) for the sale or transfer of such aggregated or non-personally identifiable research, statistical, or intelligence data to our Partners. Kyno Health also reserves the right to utilize information provided by or about the User for the following purposes:</li>
  
            <li>Publishing such information on the Website.</li>
            <li>Contacting Users for offering new products or services.</li>
            <li>Contacting Users for taking product and Service feedback.</li>
            <li>Analyzing software usage patterns for improving product design and utility.</li>
            <li>Analyzing anonymized information for commercial use.</li>
          </ul>
          <p><strong>You hereby consent to such use of such information by Kyno Health and our Partner</strong></p>

          <ul>
            <li>The collection of information categorized as &lsquo;sensitive personal data or information&rsquo; under the SPI Rules requires your explicit consent. By agreeing to this Privacy Policy, you provide the necessary consent as mandated by applicable law. Failure to provide such consent may result in our Services being unavailable to you.</li>
            <li>Personally identifiable information provided by Users on the Website or App is used to help them describe or identify themselves. Other information, that does not personally identify Users individually, is collected by Kyno Health or our Partners (such as usage patterns mentioned above) and is solely owned by Kyno Health or its partners. We or our Partners may use this information in aggregated or non-personally identifiable forms for research, statistical analysis, and business intelligence purposes and may sell or transfer such aggregated or non-personally identifiable research, statistical, or intelligence data to third parties. Specifically, we and our Partners retain the right to utilize anonymized User demographic and health information for the following purposes:</li>
   
            <li>Analyzing software usage patterns for improving product design and utility.</li>
            <li>Analyzing such information for research and development of new technologies.</li>
            <li>Using analysis of such information in other commercial product offerings of Kyno Health or our Partners.</li>
            <li>Sharing analysis of such information with third parties for commercial use.</li>
 
            <li>It is your responsibility to ensure the accuracy of the information you provide to us, such as your contact details during account registration. Should your personal information change, you can update, correct, or amend it by accessing your profile information on the Websites or App, or by contacting us at support@kynohealth.com. We will make reasonable efforts to update our active databases promptly upon receiving your request. If any information provided by you is found to be untrue, inaccurate, outdated, or incomplete, or if Kyno Health has reasonable grounds to suspect so, we reserve the right to suspend or terminate your access to the Services at our discretion.</li>

            <li>If you wish to deactivate your account or request the cessation of our use of your information for service provision, please contact us via support@kynohealth.com. We will retain your information as long as your account remains active and as necessary to fulfill our obligations to you. Information will not be retained longer than legally permissible or required under applicable laws. After a certain period, your data may be anonymized and aggregated, used by us or our Partners solely for analytical purposes to improve or enhance our Services.</li>

            <li>Kyno Health may require Users to make payments for Services using credit cards, debit cards, net banking, or other online payment methods. We collect and utilize Users' financial information, such as credit card numbers or bank account details, solely for billing and payment purposes. This information may be disclosed to third parties as necessary to facilitate these transactions. Credit card verification is handled securely by third-party payment gateways using encryption technology. Users are advised to exercise caution when using internet-based transactions, as no technology is entirely fail-safe.</li>

            <li>Due to internet communication standards, when users visit the Website or App, we automatically receive the URL of the site from which they arrived, their IP address, the type of web browser used, and other technical details like email usage patterns and ISP names. This information helps Kyno Health analyze overall usage trends and improve our Services. While we may have access to the linkage between IP addresses and personally identifiable information, this data is not shared with third parties. However, we may share anonymized, aggregated findings with advertisers, sponsors, investors, and strategic partners for business growth purposes.</li>

            <li>The Website and App may utilize cookies to store non-sensitive data for technical administration, research, and user management purposes. During service optimization or advertising, authorized third parties may place unique cookies on Users' browsers. These cookies do not store any personal information of the User.</li>

            <li>To access all features on our Website or App, Users must create an account and provide personal information such as name, email address, address, date of birth, gender, phone number, and a chosen password. Additional optional information may be requested on the registration page. By completing the registration, Users consent to use this personal information by Kyno Health to provide personalized Services and improve user experience.</li>

            <li>This Privacy Policy applies to all Websites, App, and Services owned and operated by Kyno Health. We do not control the privacy practices of sites linked within our Services, which may place their cookies or collect personal data independently. Kyno Health does not endorse or guarantee the accuracy, integrity, or quality of information on third-party websites linked to our Services. Users are encouraged to review the privacy policies of these websites before accessing them.</li>

            <li>The Services may allow Users to communicate with each other or post information accessible to others. Such Users, including moderators or administrators, are not authorized representatives of Kyno Health. Their views or statements do not necessarily reflect those of Kyno Health, and they do not have the authority to bind Kyno Health to any agreement. Kyno Health disclaims liability for any reliance on or misuse of such information posted by Users or visitors.</li>

            <li>Kyno Health may periodically conduct surveys to gather feedback on Users' experiences with the Websites, apps, and Services. These surveys may request demographic information like age, gender, and education. Survey data helps us evaluate and enhance the information and services we provide. Users providing feedback may be contacted individually for follow-up based on concerns raised during evaluations. Demographic information and web log data may be stored for future quality improvement assessments.</li>

            <li>Comments or questions sent to us via email or secure messaging forms are shared internally with our employees and healthcare professionals best equipped to address them. Once a comprehensive response is provided, we archive these messages. Some services, like automated appointment scheduling or prescription refills, interact directly with other Kyno Health data systems. Information from these transactions may be stored in these systems to support ongoing service improvements and patient care.</li>

            <li>Our Websites and Apps may include social media features, such as Facebook buttons, which may collect your IP address and page visit information to function properly. Your interactions with these features are governed by the privacy policies of the companies providing them.</li>

            <li>With your permission, the App may utilize the geolocation feature of your mobile device or similar device feature for Website access. Information collected includes device type, operating system version, and device identifier. Kyno Health and our Partners do not share your location information with any third party. You can opt out of location-based services on your mobile device by adjusting the appropriate settings.</li>

            <li>Kyno Health does not collect User information from external sources like public records or private organizations, except as necessary for User registration and service delivery, as agreed upon in the Terms of Use.</li>

            <li>Kyno Health has implemented international best practices, security policies, rules, and technical measures to protect personal data under its control from unauthorized access, misuse, disclosure, modification, or accidental loss. However, Kyno Health and its Partners are not liable for any data loss or theft due to unauthorized access to Users' electronic devices used to access our Services.</li>

            <li>We and our Partners employ reasonable security practices and procedures commensurate with the nature of Kyno Health's business and the information collected. These measures include data encryption during transmission, regular password, and production key changes, secure server access, and routine security updates.</li>

            <li>Kyno Health respects your privacy rights and will only disclose personal information when required by law, regulation, law enforcement agency, governmental authority, or legal proceedings. We may also disclose information to protect our rights, property, or safety, prevent fraud or credit risk, or enforce our Terms of Use at our discretion.</li>

            <li>In the event of a reorganization or sale of Kyno Health's assets, personal information provided by Users may be transferred or disclosed to third parties. These parties will have the right to continue using the information following our Terms of Use.</li>

            <li>To provide Users with Services, Kyno Health may share personal information with third-party contractors who assist in delivering these Services. While these contractors do not have independent rights to share this information, certain service providers may have their privacy policies governing the use and disclosure of personal information collected in connection with the Services.</li>
          </ul>

          <h4>4. CASUAL VISITORS NOTE:</h4>
          <ul>
            <li>No sensitive personal data or information is automatically collected by Kyno Health from any casual visitors of this website, who are merely perusing the site.</li>
            <li>Nevertheless, certain provisions of this Privacy Policy are applicable to even such casual visitors, and such casual visitors are also required to read and understand the privacy statements set out herein, failing which they are required to leave the Website immediately.</li>
          </ul>

          <h4>5. CONFIDENTIALITY AND SECURITY</h4>
          <ul>
            <li>Your Personal Information is stored electronically by Kyno Health on its own or its employees and Partners' equipment. This information may also be occasionally converted into physical form. We take necessary precautions to safeguard your personal information both online and offline, implementing reasonable security practices and measures. These include managerial, technical, operational, and physical security controls appropriate to the information collected and the nature of Kyno Health's business.</li>
            <li>No administrator at Kyno Health will have access to your password. You must protect against unauthorized access to your password, computer, and mobile devices. Always remember to log out from the Website when your session is complete. Kyno Health and its Partners assume no liability for any unauthorized use of your account or password. If you suspect any unauthorized use, promptly notify Kyno Health via email at support@kynohealth.com. You are responsible for indemnifying Kyno Health, its employees, and Partners for any losses incurred due to unauthorized use of your account or password.</li>
    
            <li>Kyno Health restricts access to User information to employees on a need-to-know basis only.</li>
            <li>The Services include functionality that allows patients, customers, and other stakeholders (such as doctors, labs, pharmacies, and customer care executives) to access relevant information. Accordingly, Kyno Health may retain and disclose such records to the respective patients, their doctors, or other involved stakeholders.</li>
            <li>Despite the above measures, Kyno Health cannot guarantee the confidentiality, security, or distribution of your personal information by our Partners and third parties beyond the scope of our agreements with them. Additionally, Kyno Health and its Partners are not liable for breaches of security or actions by third parties or events beyond our reasonable control, including government actions, computer hacking, unauthorized access to computer systems and storage devices, system failures, breaches of security and encryption, internet or telephone service disruptions affecting the User, and other similar events.</li>
          </ul>

          <h4>6. CHANGE TO PRIVACY POLICY</h4>
          <ul>
            <li>Kyno Health reserves the right to update this Privacy Policy at any time, with or without prior notice. If there are significant changes in how Kyno Health handles Users' personally identifiable information or in the Privacy Policy itself, Kyno Health will notify Users by displaying a notice on the Website or sending an email, as described above. This allows Users to review the revised terms before continuing to use the Services. If you disagree with any changes and no longer wish to use the Services, you can deactivate your account by contacting support@kynohealth.com. Unless otherwise stated, Kyno Health's current Privacy Policy applies to all information we have about you and your account.</li>
            <li>By using the Services, accessing the Website, or using the App after receiving a notice of changes or seeing the changes posted on the Website, you consent to the updated terms.</li>
          </ul>

          <h4>7. CHILDREN&rsquo;S AND MINOR&rsquo;S PRIVACY</h4>
          <ul>
            <li>Kyno Health strongly advises parents and guardians to monitor the online activities of their minor children and to consider using parental control tools offered by online services and software providers to create a child-friendly online environment. These tools can prevent minors from disclosing their personal information, such as name and address, online without parental permission. While the Kyno Health Website, App, and Services are not intended for use by minors, Kyno Health respects the privacy of minors who may inadvertently use the internet or the mobile application.</li>
          </ul>

          <h4>8. CONSENT TO THIS POLICY</h4>
          <p>You acknowledge that this Privacy Policy is a part of the Terms of Use of the Website and the other Services, and you unconditionally agree that becoming a User of the Website, the App, and its Services signifies your assent to this Privacy Policy. Your visit to the Website, use of the App, and use of the Services is subject to this Privacy Policy and the Terms of Use.</p>

          <h4>9. ADDRESS FOR PRIVACY QUESTIONS</h4>
          <p>Should you have questions about this Privacy Policy or Kyno Health's information collection, use and disclosure practices, you may contact us at support@kynohealth.com. We will use reasonable efforts to respond promptly to any requests, questions or concerns, which you may have regarding our use of your personal information</p>

          <p>If you have any grievance with respect to our use of your information, you may communicate such grievance to:</p>
          <p><strong>Phone: +91-9953104104 <br/> Email: support@kynohealth.com</strong></p>

    </div >
      </section >

    </>
  )
}

export default index