import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Comprehensive Dizziness Treatment: Expert Care for a Balanced Life</h3>    
    <p className='d-md-none d-lg-none'>If you're grappling with persistent dizziness, seeking the expertise of a qualified healthcare professional is crucial for regaining your equilibrium and improving your quality of life. This service page delves into the importance of consulting doctors specializing in dizziness treatment, outlining the diagnostic process, potential causes, and effective treatment options.</p>

<p className='mob-noee'>If you're grappling with persistent dizziness, seeking the expertise of a qualified healthcare professional is crucial for regaining your equilibrium and improving your quality of life. This service page delves into the importance of consulting doctors specializing in dizziness treatment, outlining the diagnostic process, potential causes, and effective treatment options.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
