import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Comprehensive Care for Stomach Issues - Your Path to Digestive Health
</h3>    
    <p className='d-md-none d-lg-none'>Are you or a loved one experiencing persistent stomach problems that disrupt your daily life? Digestive issues can be uncomfortable, inconvenient, and even debilitating. At Kyno Health clinic, we specialize in diagnosing and treating a wide range of stomach issues. Our dedicated team of experienced doctors is here to provide you with the expert care and guidance you need to regain your digestive health.</p>

<p className='mob-noee'>Are you or a loved one experiencing persistent stomach problems that disrupt your daily life? Digestive issues can be uncomfortable, inconvenient, and even debilitating. At Kyno Health clinic, we specialize in diagnosing and treating a wide range of stomach issues. Our dedicated team of experienced doctors is here to provide you with the expert care and guidance you need to regain your digestive health.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
