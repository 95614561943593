import React from "react";

function index() {
  return (
    <>
      <section className="ms">
        <div className="container">
          <h3>NATURE AND APPLICABILITY OF TERM</h3>

          <p>
            Please carefully review these terms and conditions ("Terms") and the
            privacy policy available at{" "}
            <a href="https://www.kynohealth.com/">https://www.kynohealth.com</a>{" "}
            ("Privacy Policy") before you access the Website or App or avail
            yourself of the Services we provide. These Terms, along with the
            Privacy Policy, collectively constitute a legal agreement
            ("Agreement") between you and Kyno Health governing your visit to
            the Website and your use of the Services (as defined below).
          </p>
          <p>This Agreement applies to you whether you are:</p>
          <ul>
            <li>
              A patient, their representative, attendant, relative, friend, or
              affiliate ("you" or "User"), or
            </li>
            <li>A user of the Website or App ("you" or "User").</li>
          </ul>
          <p>
            This Agreement encompasses all services made available by Kyno
            Health on the Website or App. Please note that the Services may
            change from time to time at the sole discretion of Kyno Health.
            Consequently, the Agreement will apply to your visit to and use of
            the Website or App to avail of the Services, as well as to all
            information you provide on the Website or App at any given point in
            time.
          </p>
          <p>
            This Agreement delineates the terms and conditions under which you
            are permitted to use the Website and App and outlines how we will
            manage your account while you are registered as a member with us.
            Should you have any questions regarding any part of the Agreement,
            please feel free to contact us at support@kynohealth.com.
          </p>
          <p>
            By downloading or accessing the App or Website to use the Services,
            you irrevocably accept all the terms and conditions stipulated in
            this Agreement and agree to comply with them fully. This Agreement
            supersedes all previous oral and written terms and conditions (if
            any) communicated to you. By availing yourself of any Service, you
            signify your agreement and acceptance of this Agreement.
          </p>
          <p>
            We reserve the right to modify or terminate any portion of the
            Agreement for any reason and at any time. It is advisable to read
            the Agreement at regular intervals. Your use of the App or Website
            following any such modification constitutes your agreement to follow
            and be bound by the Agreement as modified. Any additional terms and
            conditions, disclaimers, privacy policies, and other policies
            applicable in general and/or to specific areas of this Website or to
            particular Services are also considered part of the Agreement.
          </p>
          <p>
            You acknowledge that you will be bound by this Agreement to avail
            yourself of any of the Services offered by us. If you do not agree
            with any part of the Agreement, please do not use the Website or
            avail yourself of any Services. Your access to and use of the
            Website and the Services will be solely at the discretion of Kyno
            Health.
          </p>
          <p>
            The Agreement is published in compliance with and is governed by the
            provisions of Indian law, including but not limited to:
          </p>
          <ul>
            <li>The Indian Contract Act, 1872,</li>
            <li>The (Indian) Information Technology Act, 2000, and</li>
            <li>
              The rules, regulations, guidelines, and clarifications framed
              thereunder, including the (Indian) Information Technology
              (Reasonable Security Practices and Procedures and Sensitive
              Personal Information) Rules, 2011 (the "SPI Rules"), and the
              (Indian) Information Technology (Intermediaries Guidelines) Rules,
              2011 (the "IG Rules").
            </li>
          </ul>

          <h3>CONDITIONS OF USE</h3>

          <p>
            To register, use the Services, or visit or use the Website in any
            manner, you must be 18 years of age or older. By registering,
            visiting, using the Website, or accepting this Agreement, you
            represent and warrant to Kyno Health that you meet this age
            requirement. You affirm that you possess the right, authority, and
            capacity to utilize the Website and the Services offered through it,
            and you agree to abide by the terms of this Agreement.
          </p>

          <h3>END-USER ACCOUNT AND DATA PRIVACY</h3>

          <p>
            The terms "personal information" and "sensitive personal data or
            information" are defined under the SPI Rules and are detailed in the
            Privacy Policy. Kyno Health and its Partners, through the Services,
            may collect information related to the devices you use to access the
            Website, your location, and anonymous data on your usage patterns.
            This information will be utilized solely to enhance the quality of
            the services and develop new offerings. The Website and App enable
            Kyno Health and its Partners to access users' personal email
            addresses or phone numbers for communication and analytics purposes.
          </p>
          <h4>3.1 Privacy Policy Overview</h4>
          <p>The Privacy Policy specifies:</p>
          <ul>
            <li>
              The types of information collected from users, including sensitive
              personal data or information;
            </li>
            <li>The purposes, means, and methods of using such information;</li>
            <li>
              The parties to whom Kyno Health may disclose such information;
            </li>
            <li>Additional information is mandated by the SPI Rules.</li>
          </ul>
          <h4>3.2 User Awareness and Responsibilities</h4>
          <p>
            Users are expected to read and understand the Privacy Policy to
            ensure they are aware of:
          </p>
          <ul>
            <li>The fact that certain information is being collected;</li>
            <li>The purpose for which the information is collected;</li>
            <li>The intended recipients of the information;</li>
            <li>The methods of collection and retention of the information;</li>
            <li>
              The various rights available to users concerning such information.
            </li>
          </ul>
          <p>
            Kyno Health and its Partners are not responsible for the
            authenticity or accuracy of the personal information or sensitive
            data provided by the user to Kyno Health or any entity acting on
            behalf of Kyno Health or its Partners.
          </p>
          <h4>3.3 Account Security</h4>
          <p>
            Users are responsible for maintaining the confidentiality of their
            account access information and passwords if they are registered on
            the Website or App. Users are accountable for all activities
            conducted using their account and password, whether or not they
            authorize it. Users must immediately notify Kyno Health of any
            actual or suspected unauthorized use of their account or password.
            Although Kyno Health is not liable for losses incurred due to
            unauthorized use of your account, you may be liable for the losses
            of Kyno Health or others resulting from such unauthorized use.
          </p>
          <h4>3.4 Accuracy of Provided Information</h4>
          <p>
            If a user provides information that is untrue, inaccurate, outdated,
            or incomplete (or becomes such), or if Kyno Health has reasonable
            grounds to suspect that the information is untrue, inaccurate,
            outdated, or incomplete, Kyno Health reserves the right to
            discontinue the Services to the user at its sole discretion.
          </p>
          <h4>3.5 Contact Information Maintenance</h4>
          <p>
            Users are responsible for keeping their mobile number and email ID
            updated on the Website or App. All reminders and notifications will
            be sent to the account associated with the provided mobile number
            and/or email ID. Whenever users change any contact information
            (mobile or email), Kyno Health will send a confirmation. Kyno Health
            is not responsible for any loss or inconvenience caused by the
            user's failure to update their contact details.
          </p>
          <h4>3.6 Usage of Collected Information</h4>
          <p>
            Kyno Health and its Partners may use information collected from
            users for purposes such as debugging customer support-related
            issues. Kyno Health may offer Services that allow users to
            communicate with Kyno Health, its authorized representatives,
            doctors, or similar stakeholders via online chat, text messages,
            video calls, or voice calls. Records of such communications may be
            recorded and stored on Kyno Health's or its Partners' servers and
            are managed in accordance with the Privacy Policy. Kyno Health and
            its Partners are not liable if users choose to use this facility to
            interact with doctors or clinicians or if the facility is not used
            as intended.
          </p>
          <h4>3.7 Personal Health Record Facility</h4>
          <p>
            Users may be provided with a personal health record facility to add,
            modify, delete, and manage their health-related records and
            information. Specific terms related to this facility are outlined
            below, in addition to the Terms and Privacy Policy:
          </p>
          <ul>
            <li>
              This facility is enabled or created for users only after they have
              signed up and explicitly accepted the Terms.
            </li>
            <li>Information in the Health Account can be either:</li>
            <ul>
              <li>
                Patient-created: Information generated and uploaded by the user.
              </li>
              <li>
                Kyno Health-created: Information generated by using a Service
                from Kyno Health, such as consultations or diagnostic tests.
              </li>
            </ul>
            <li>
              Kyno Health-created information is provided "as-is," with no
              representations made regarding its accuracy.
            </li>
            <li>
              Users are solely responsible for the accuracy, adequacy, and
              reliability of any Patient-created information they generate or
              create. Users indemnify and hold harmless Kyno Health and its
              Partners from any inaccuracies, errors, losses, or liabilities
              arising from Patient-created information.
            </li>
            <li>
              The personal health record facility is provided on a best-efforts,
              "as-is" basis. While Kyno Health strives for high service
              availability, it is not liable for any interruptions in accessing
              the Services.
            </li>
            <li>
              Any health-related reminders or notification services (e.g.,
              medication or appointment reminders) are supplementary
              notifications. Kyno Health is not liable for any issues arising
              from the non-delivery, late delivery, or incorrect delivery of
              these notifications despite best efforts.
            </li>
            <li>
              Kyno Health employs industry-standard security and encryption for
              personal health records. However, Kyno Health cannot guarantee the
              prevention of unauthorized access if login credentials are lost or
              compromised. Users must safeguard their login credentials and
              report any actual or suspected breaches to support@kynohealth.com.
            </li>
            <li>
              If users access their dependents' records through their personal
              health records by registering dependents under their account, they
              are responsible for those records and obligations as if the
              dependents maintained their own separate personal health records.
            </li>
            <li>
              If users grant others access to their personal health records,
              they are responsible for all actions, access, and transactions
              made by those individuals. Kyno Health and its Partners are not
              liable for any actions, transactions, losses, or liabilities
              arising from such access.
            </li>
          </ul>

          <h3>LISTING CONTENT AND DISSEMINATING INFORMATION</h3>

          <p>
            Kyno Health collects and displays relevant information about
            Practitioners listed on the Website, such as their specialization,
            qualifications, fees, location, visiting hours, and other details.
            This information is gathered directly or indirectly, and Kyno Health
            makes reasonable efforts to ensure it is updated frequently. Despite
            these efforts, Kyno Health cannot be held liable for any
            inaccuracies or incompleteness in the information provided.
          </p>
          <h4>4.1 Service Provision and Warranty Disclaimer</h4>
          <p>
            The Services offered by Kyno Health or any of its licensors or
            Partners are provided on an "as is" and "as available" basis without
            any warranties or conditions, whether express or implied. This
            includes implied warranties of merchantability, accuracy, fitness
            for a particular purpose, title, and non-infringement, whether
            arising by statute, law, or from a course of dealing or usage of
            trade. Kyno Health and its Partners do not make any representation,
            warranty, or guarantee, express or implied, regarding the Website,
            App, or Services. They do not guarantee the accuracy or completeness
            of any content or information provided by users on the Website. To
            the fullest extent permitted by law, Kyno Health and its Partners
            disclaim all liability arising from the user&rsquo;s use or reliance
            on the Website, App, the Services, representations, and warranties
            made by other users, the content or information provided by users on
            the Website, or any opinion or suggestion given by Kyno Health, its
            Partners, or any user regarding any user or services provided by
            such user.
          </p>
          <h4>4.2 Third-Party Links</h4>
          <p>
            The Website or App may contain links to third-party websites,
            affiliates, and business partners. Kyno Health has no control over
            and is not responsible for the content, accuracy, validity,
            reliability, or quality of such websites. The inclusion of any link
            on the Website or App does not imply endorsement by Kyno Health.
            Users access and use these links and services at their own risk.
          </p>
          <h4>4.3 Limitation of Liability</h4>
          <p>
            Kyno Health and its Partners assume no responsibility and shall not
            be liable for any damages to or viruses that may infect a
            user&rsquo;s equipment due to accessing, using, or browsing the
            Website or App or downloading any material, data, text, images,
            video content, or audio content from the Website or App. If a user
            is dissatisfied with the Website or App, their sole remedy is to
            discontinue using the Website or App.
          </p>
          <h4>4.4 Fraudulent Information and Indemnity</h4>
          <p>
            If Kyno Health determines through feedback or other means that a
            user has provided fraudulent, inaccurate, incomplete, or false
            information, Kyno Health reserves the right to immediately suspend
            the user's access to the Website or any of their accounts with Kyno
            Health. Kyno Health may also declare this suspension on the website
            alongside the user's name to protect its business and the interests
            of users. The user shall be liable to indemnify Kyno Health and its
            Partners for any losses incurred due to the user's
            misrepresentations or fraudulent feedback that have adversely
            affected Kyno Health, its Partners, or its users.
          </p>

          <h4>
            BOOKING APPOINTMENTS, DIAGNOSTIC TESTS, ORDERING MEDICINES, AND
            INTERACTING WITH MEDICAL SERVICE PROVIDERS
          </h4>

          <p>
            <strong>5.1</strong> Kyno Health endeavors to secure confirmed
            appointments with medical service providers (such as doctors) for
            users who request appointments through the Website or App. However,
            Kyno Health and its Partners do not guarantee that a user will
            obtain a confirmed appointment. Additionally, Kyno Health and its
            Partners are not liable if an appointment, once confirmed, is later
            canceled, postponed, or rescheduled by the hospital or medical
            service provider. They are also not liable if the medical service
            provider is unavailable at the appointed time.
          </p>
          <p>
            <strong>5.2</strong> Kyno Health and its Partners may offer
            value-added services that connect users directly to medical service
            providers through video calls, voice calls, text messages, online
            chats, or other electronic means. The information exchanged during
            these interactions is stored and used in accordance with the Privacy
            Policy. However, users have the discretion to decide whether or not
            to utilize these services if they are available.
          </p>
          <p>
            <strong>5.3</strong> You understand and agree that any interactions
            and associated issues with other Users, including but not limited to
            your health issues and your experiences, are strictly between you
            and the other Users. You shall not hold Kyno Health or its Partners
            responsible for any such interactions and associated issues, and
            Kyno Health will not be held responsible or liable for them. For the
            avoidance of doubt, the Services are not to provide any healthcare
            or medical advice or diagnosis, and hence Kyno Health and its
            Partners are not responsible for any medical or other outcomes
            between you and the medical service providers you interact with,
            pursuant to any interactions on the Website or App. If you decide to
            engage with a medical service provider to provide medical services
            to you, you do so at your own risk. Kyno Health or its Partners
            shall not be responsible for any breach of service or service
            deficiency by any medical service provider you connect, engage, or
            transact with using the Website or App. We advise you to perform
            your own investigation or get feedback prior to selecting a doctor
            or any other service provider available on the Website or App.
          </p>
          <p>
            <strong>5.4</strong> Without prejudice to the generality of the
            above, Kyno Health or its Partners will not be liable for:
          </p>
          <ul>
            <li>
              any wrong medication or treatment quality being given by the
              doctor(s), diagnostic service provider(s), pharmacy(s), or any
              medical negligence on the part of any of the above;
            </li>
            <li>
              any type of inconvenience suffered by the User due to a failure on
              the part of the medical service provider, including the doctor to
              make themself available at the appointed time, no show by the
              doctor, inappropriate treatment, or similar difficulties;
            </li>
            <li>
              cancellation or rescheduling of booked appointments or any
              variance in the fees charged;
            </li>
            <li>
              any medical eventualities that might occur subsequent to using the
              services of a doctor, diagnostic lab services, or pharmacy whom
              the User has selected on the basis of the information available on
              the Website or App or with whom the User has booked an appointment
              or performed a transaction through the Website or App.
            </li>
          </ul>
          <p>
            <strong>5.5</strong> Additionally, Kyno Health shall not be held
            liable for any comments or feedback provided by users about the
            services rendered by another user. Any such feedback should comply
            with applicable laws and regulations. The option for users to give
            feedback is entirely at the discretion of Kyno Health and can be
            modified or withdrawn at any time without prior notice.
          </p>
          <p>
            Kyno Health reserves the right to moderate, review, and manage the
            feedback provided by users to ensure it aligns with community
            guidelines and legal requirements. This moderation process may
            include editing, removing, or refusing to display any feedback
            deemed inappropriate or non-compliant. However, Kyno Health is not
            obligated to implement any changes or actions based on the content
            of the feedback received from users.
          </p>
          <p>
            Users should be aware that while their feedback can help improve
            services and inform other users, Kyno Health does not guarantee that
            all feedback will be acted upon or that it will result in any
            specific changes. The presence of user feedback on the Website or
            App does not imply endorsement or verification by Kyno Health. Users
            are encouraged to exercise their judgment and conduct their own
            research when considering feedback related to medical service
            providers or other services offered through the Website or App.
          </p>

          <h3>ONLINE PAYMENT ON WEBSITE AND APP</h3>

          <p>
            <strong>6.1 </strong>The Website and App provide users with the
            ability to make online payments for certain Services via a secure
            third-party payment gateway. This feature allows for seamless
            transactions using various payment methods, such as credit cards and
            debit cards. While Kyno Health facilitates this process, the payment
            gateway manages transaction security and approval, ensuring that
            users' financial information is protected throughout the payment
            process.
          </p>
          <p>
            <strong>6.2</strong> If you choose to pay online, you may be
            directed to a third-party payment gateway to enable payment
            processing. This transaction will be governed by the terms and
            conditions and privacy policy of the third-party payment gateway.
            Kyno Health shall not be liable (a) if any transaction does not
            fructify or may not be completed or (b) for any failure on the part
            of the bank, the credit card, or the third-party site or agency to
            perform any of its obligations or (c) in respect of any loss or
            damage arising directly or indirectly arising out of the decline or
            acceptance of authorization for any transaction, for any reason
            whatsoever.
          </p>
          <p>
            <strong>6.3</strong> Cancellation and Refund Policy:
          </p>
          <p>
            The cancellation and refund policy is only applicable to all online
            payments made through the mobile app and website for booking
            appointments.
          </p>
          <p>In case cancellation is initiated by the end-user:</p>
          <ul>
            <li>
              <strong>
                Before Schedule Appointment Time- 100% of Consultation Fees will
                be refunded. However, please note that convenience fees will not
                be refunded.
              </strong>
            </li>

            <li>
              After Scheduled Appointment Time- neither Consultation Fees nor
              Convenience Fees will be refunded.
            </li>
          </ul>
          <p>
            At the time of cancellation, the amount will be refunded via the
            same source of payment and will be subject to the payment terms of
            the source only. Amount will be refunded within 7 working days.
          </p>
          <h3>8. CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS</h3>
          <h4>8.1 Ownership and Use of Content:</h4>
          <p>
            The content on the Website is either user-generated or owned by Kyno
            Health and its Partners. Any information collected directly or
            indirectly from users belongs to Kyno Health and its Partners.
            Unauthorized copying of this copyrighted content for commercial
            purposes or profit will violate copyright laws, and Kyno Health and
            its Partners reserve the right to take legal action accordingly.
          </p>
          <h4>8.2 Permitted Access and Usage:</h4>
          <p>
            Kyno Health permits users to view and access content on the Website
            solely for purposes related to ordering, receiving, delivering,
            sharing, storing, and communicating in accordance with the
            Agreement. The content on the Website, including information, text,
            graphics, images, logos, button icons, software code, design, and
            their arrangement (collectively, "Kyno Health Content"), is the
            property of Kyno Health or its Partners and is protected by
            copyright, trademark, and other laws. Users are prohibited from
            modifying, reproducing, displaying, publicly performing,
            distributing, or using the Kyno Health Content for any public,
            commercial, or personal gain.
          </p>
          <h4>8.3 Restrictions on Monitoring and Benchmarking:</h4>
          <p>
            Users are not allowed to access Kyno Health's services to monitor
            their availability, performance, or functionality or for any other
            benchmarking or competitive purposes.
          </p>
          <h3>9. REVIEWS AND FEEDBACK</h3>
          <p>
            By using this Website, you agree that any information you share with
            Kyno Health or any other user, medical service provider, or entity
            using the Website, App, or Services will be subject to our Privacy
            Policy.
          </p>
          <p>
            You are solely responsible for the content you choose to submit for
            publication on the Website, including feedback, ratings, or reviews
            ("Critical Content"). Kyno Health acts as an 'intermediary' under
            the Information Technology Act of 2000 regarding the publication of
            Critical Content. Kyno Health disclaims all responsibility for
            Critical Content, and its role is limited to its obligations as an
            intermediary under the Act. Kyno Health is not obligated to
            compensate any user for republishing content across its or its
            partners' platforms.
          </p>
          <p>
            Your publication of reviews and feedback on the Website is governed
            by Clause 10, as stated below. You agree not to post any content on
            the Website that
          </p>
          <p>
            (a) infringes any third party intellectual property, publicity, or
            privacy rights, or
          </p>
          <p>
            (b) violates any applicable law or regulation, including the IG
            Rules and SPI Rules.
          </p>
          <p>
            Kyno Health may, at its discretion, choose not to publish your
            reviews and feedback as required by applicable law and in accordance
            with Clause 5 of these Terms.
          </p>
          <p>
            You consent to Kyno Health contacting you via telephone, email, SMS,
            or other electronic communication methods for purposes such as
            obtaining feedback on the Website, App, or Kyno Health's services;
            obtaining feedback on medical service providers; or resolving
            complaints, inquiries, or issues regarding your Critical Content.
            You agree to cooperate fully with Kyno Health in response to such
            communications.
          </p>
          <h3>10. RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h3>
          <p>
            <strong>10.1</strong> According to Regulation 3(2) of the IG Rules,
            Kyno Health notifies Users that they are prohibited from hosting,
            displaying, uploading, modifying, publishing, transmitting,
            updating, or sharing any information that:
          </p>
          <ul>
            <li>
              <strong>
                It belongs to another person and is not rightfully owned by the
                User.
              </strong>
            </li>

            <li>
              Is severely harmful, harassing, blasphemous, defamatory, obscene,
              pornographic, pedophilic, libelous, invasive of privacy, hateful,
              racially or ethnically objectionable, disparaging, relates to or
              encourages money laundering or gambling, or is otherwise unlawful.
            </li>

            <li>Harms minors in any way.</li>

            <li>
              Infringes any patent, trademark, copyright, or other proprietary
              rights.
            </li>

            <li>Violates any current law.</li>

            <li>
              Deceives or misleads the recipient about the origin of such
              messages or communicates offensive or threatening information.
            </li>

            <li>Impersonates another person.</li>

            <li>
              It contains software viruses or any other computer code, files, or
              programs designed to interrupt, destroy, or limit the
              functionality of any computer resource.
            </li>

            <li>
              It threatens the unity, integrity, defense, security, or
              sovereignty of India, friendly relations with foreign states, and
              public order; it incites the commission of any offense; it
              prevents investigation of any offense; and it insults any other
              nation.
            </li>
          </ul>
          <p>
            These guidelines are aimed at ensuring compliance with legal
            requirements and maintaining a safe and respectful online
            environment for all users of the Website, App, or Services provided
            by Kyno Health.
          </p>
          <p>
            <strong>10.2</strong> Users are also strictly prohibited from:
          </p>
          <ul>
            <li>
              <strong>
                Violating or attempting to violate the integrity or security of
                the Website, App, or any content provided by Kyno Health.
              </strong>
            </li>

            <li>
              Transmitting any information, including job posts, messages, or
              hyperlinks, through the Website or App that disrupts, competes
              with, or undermines the provision of services by Kyno Health.
            </li>

            <li>
              Intentionally submitting incomplete, false, or inaccurate
              information.
            </li>

            <li>Initiating unsolicited communications to other Users.</li>

            <li>
              Using any automated system, software, tool, agent, or mechanism
              (such as spiders, robots, avatars, or intelligent agents) to
              browse or search the Website.
            </li>

            <li>
              Attempting to decipher, decompile, disassemble, or reverse
              engineer any part of the Website or App.
            </li>

            <li>
              Copying or duplicating Kyno Health content or other information
              available on the Website or App in any manner.
            </li>

            <li>
              Framing, hotlinking, or deep linking any Kyno Health content
              without explicit permission.
            </li>
          </ul>
          <p>
            These regulations are in place to safeguard the operational
            integrity, security, and fair use of the Website and App, ensuring a
            positive and compliant user experience for all.
          </p>
          <p>
            <strong>10.3</strong> Kyno Health or its partners, upon discovering
            or receiving written or electronically signed notification with an
            electronic signature from an affected individual regarding any
            user-generated content that violates the terms outlined above, or
            upon notification from the appropriate government authority that the
            Website or App is being used for unlawful activities or in violation
            of Clauses 10. a and 10. b, reserves the right to take action. This
            may include removing or disabling access to such material or
            information as per Clause 10. Kyno Health is obligated under the IG
            Rules to act promptly, typically within thirty-six hours of becoming
            aware of such violations. Additionally, Kyno Health may collaborate
            with users to disable any content that breaches applicable laws.
          </p>
          <p>
            Furthermore, Kyno Health retains the right to preserve such
            information and associated records for a minimum of 90 days. This
            retention is necessary to facilitate investigations by governmental
            authorities as required.
          </p>
          <p>
            These measures are implemented to uphold legal compliance, protect
            user interests, and maintain the integrity and lawful operation of
            the Website and App provided by Kyno Health and its partners.
          </p>
          <p>
            <strong>10.4</strong> If a User fails to comply with any applicable
            laws, rules, regulations, or the terms of the Agreement (including
            the Privacy Policy), Kyno Health reserves the right to take
            immediate action. This includes terminating the User's access or
            usage rights to the Website, App, and Services, as well as removing
            any non-compliant information from the Website.
          </p>
          <p>
            This action is necessary to ensure adherence to legal requirements,
            maintain the integrity of services offered by Kyno Health, and
            safeguard the rights and interests of all users of the platform.
          </p>
          <p>
            <strong>10.5</strong> Kyno Health reserves the right to disclose or
            transfer user-generated information to its affiliates or
            governmental authorities as permitted or required by applicable law.
            By using the Website, App, or Services, you consent to such
            transfers.
          </p>
          <p>
            Kyno Health will comply with any lawful directives from government
            agencies or courts to disable access to user-generated information
            deemed illegal by competent governmental authorities. This ensures
            adherence to legal obligations, facilitates regulatory compliance,
            and supports the enforcement of applicable laws and regulations.
          </p>
          <h3>11. INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            <strong>11.1 </strong> The contents of the Website and App are
            protected under Indian intellectual property laws, including
            trademarks and copyrights. Reproduction, retransmission, or any
            public or commercial use of any material from the Website and App is
            strictly prohibited. The logos, service marks, and trademarks ("IP
            Marks") displayed on the Website or App are either owned by Kyno
            Health or its Partners or licensed to Kyno Health or its Partners by
            their respective owners for use. Users may only use this material as
            expressly authorized by Kyno Health or its Partners and must not
            copy, transmit, or create derivative works without explicit
            authorization.
          </p>
          <p>
            <strong>11.2 </strong> The User acknowledges and agrees not to
            upload, post, reproduce, or distribute any content on or through the
            Website or App that is protected by copyright or other proprietary
            rights of a third party without obtaining permission from the owner
            of such rights. Any copyrighted or proprietary content distributed
            on or through the Website or App with the owner's consent must
            include the appropriate copyright or proprietary rights notice.
            Unauthorized submission or distribution of copyrighted or
            proprietary content is illegal and may result in personal liability
            or criminal prosecution for the User. Nothing on the Website or App
            should be interpreted as granting, implicitly, by estoppel, or
            otherwise, any license or right to use any trademarks without prior
            written permission from Kyno Health.
          </p>
          <h3>12. TERMINATION</h3>
          <p>
            <strong>12.1</strong> Kyno Health reserves the right to suspend or
            terminate a User&rsquo;s access to the Website and the Services with
            or without notice and to exercise any other remedy available under
            the law where:
          </p>
          <ul>
            <li>
              <strong>
                Such User breaches any terms and conditions of the Agreement;
              </strong>
            </li>

            <li>
              A third party reports a violation of any of its rights as a result
              of your use of the Services;
            </li>

            <li>
              Kyno Health is unable to verify or authenticate any information
              provided to Kyno Health by a User;
            </li>

            <li>
              Kyno Health has reasonable grounds for suspecting any illegal,
              fraudulent, or abusive activity on the part of such User or
            </li>

            <li>
              Kyno Health believes in its sole discretion that User&rsquo;s
              actions may cause legal liability for such User, other Users, or
              for Kyno Health or its Partners or are contrary to the interests
              of the Website.
            </li>
          </ul>
          <p>
            <strong>12.2</strong> Once temporarily suspended, indefinitely
            suspended, or terminated, the User may not continue to use the
            Website under the same account, a different account, or re-register
            under a new account. On termination of an account due to the reasons
            mentioned herein, such User shall no longer have access to data,
            messages, files, and other material kept on the Website by such
            User. The User shall ensure that he/she/it has continuous backup of
            any medical services the User has rendered in order to comply with
            the User&rsquo;s record-keeping process and practices.
          </p>
          <h3>13. DISCLAIMER OF WARRANTIES</h3>
          <p>
            <strong>13.1</strong> All information provided on the Website or App
            (the "Information") is given under the condition that Users
            independently verify its accuracy, completeness, or suitability
            before relying on it or making any decisions. Kyno Health and its
            Partners disclaim responsibility for any such reliance.
            Additionally, Kyno Health and its Partners are not liable for any
            data added, provided, stored, or managed by Users, including
            personally identifiable information.
          </p>
          <p>
            <strong>13.2</strong> The Information does not constitute an
            invitation or recommendation to seek medical services from Kyno
            Health or its Partners, nor does it replace professional advice or
            solicitations regarding medical services/products. Users are urged
            to seek appropriate professional advice concerning the evaluation of
            any specific opinion, advice, product, service, or other
            Information.
          </p>
          <p>
            <strong>13.3</strong> All information on the Website is provided "as
            is" without any express or implied warranties, including but not
            limited to warranties of merchantability, fitness for a particular
            purpose, title, non-infringement, security, or accuracy. In no event
            shall Kyno Health or its Partners be liable for any special, direct,
            indirect, or consequential damages resulting from loss, whether in
            contract, negligence, or other tortious actions, arising from or in
            connection with the use or performance of the Information.
            Information available on hyperlinked sites and any third-party
            content is subject to the terms and conditions stated therein.
          </p>
          <h3>14. LIMITATION OF LIABILITY</h3>
          <p>
            <strong>14.1</strong> Information on the Website or App may contain
            inaccuracies or typographical errors. While Kyno Health strives for
            accuracy, it makes no warranties or representations regarding the
            quality, accuracy, or completeness of any data or information
            provided. Kyno Health expressly disclaims all warranties of fitness
            for a particular purpose and merchantability concerning the Website
            or App and its contents, including any liability, responsibility, or
            claim, whether direct or consequential, arising from or related to
            the use of the Information on the Website.
          </p>
          <p>
            <strong>14.2</strong> In no event shall Kyno Health or its Partners
            be liable for any direct, indirect, punitive, incidental, special,
            or consequential damages resulting from (a) the use or inability to
            use the Services; (b) unauthorized access to or alteration of User
            transmissions or data; (c) any matter relating to the Services,
            including loss of use, data, or profits, arising out of or in any
            way connected with the use or performance of the Website.
            Furthermore, Kyno Health shall not be responsible for delays or
            inability to access the Website, App, Services, or related services
            due to technical reasons or reasons beyond Kyno Health's control.
            Users understand and agree that any material or data downloaded or
            obtained through the Website is done at their own discretion and
            risk, and they are solely responsible for any damage to their
            computer systems or loss of data resulting from such downloads.
            These limitations, disclaimers of warranties, and exclusions apply
            regardless of whether damages arise from the breach of contract,
            breach of warranty, negligence, or any other cause of action to the
            extent permitted by applicable law.
          </p>
          <h3>15. INDEMNITY</h3>
          <p>
            The User agrees to indemnify and hold harmless Kyno Health, its
            affiliates, officers, directors, employees, consultants, licensors,
            agents, representatives, and Partners from any and all claims,
            losses, liabilities, damages, costs (including reasonable attorney
            fees), arising from the User&rsquo;s access to or use of the
            Services, violation of this Agreement, or infringement by any other
            User using their account, of any intellectual property or other
            rights of any person or entity. Upon notification of such claims,
            losses, liabilities, or demands, Kyno Health will promptly inform
            the User. The User agrees to provide reasonable assistance, at their
            own expense, in defending against any such claims, losses,
            liabilities, damages, or costs.
          </p>
          <h3>16. APPLICABLE LAW AND DISPUTE SETTLEMENT</h3>
          <p>
            <strong>16.1</strong> This Agreement and all contractual obligations
            between the Company and the User shall be governed by the laws of
            India.
          </p>
          <p>
            <strong>16.2</strong> Any disputes arising out of or relating to
            this Agreement, your use of the Website or the Services, or the
            information accessed through them shall be exclusively subject to
            the jurisdiction of the courts in Delhi, India, subject to the
            provisions stated above.
          </p>
          <h3>17. CONTACT INFORMATION GRIEVANCE OFFICER</h3>
          <p>
            <strong>17.1</strong> If you have any questions about the Company,
            the Website, the App, this Agreement, the Services, or any related
            matters, please contact us at{" "}
            <a href="mailto:support@kynohealth.com">support@kynohealth.com</a>.
          </p>
          <p>
            <strong>17.2</strong> In accordance with the Information Technology
            Act, 2000, and its rules, if you have any grievances related to the
            Website, the App, or the Services, including any issues or concerns
            regarding the processing of information, you can reach out to our
            Grievance Officer:
          </p>
          <ul>
            <li>Phone: +91-9953104104</li>
            <li>Email: support@kynohealth.com</li>
          </ul>
          <p>
            We are committed to addressing your concerns promptly and
            effectively.
          </p>
          <h3>18. SEVERABILITY</h3>
          <p>
            If any provision of this Agreement is found to be unenforceable by a
            court of competent jurisdiction or arbitral tribunal under
            applicable law, that provision shall be deemed excluded from this
            Agreement. The remainder of the Agreement shall continue to be valid
            and enforceable as if the excluded provision did not exist. However,
            in such an event, the Agreement shall be interpreted in a manner
            that gives effect, to the greatest extent permitted by applicable
            law, to the meaning and intention of the excluded provision as
            determined by the court of competent jurisdiction or arbitral
            tribunal.
          </p>
          <p>
            This ensures that the Agreement remains enforceable to the fullest
            extent possible under the law while respecting the decision of the
            court or tribunal regarding the unenforceable provision.
          </p>
          <h3>19. WAIVER</h3>
          <p>
            No provision of this Agreement shall be waived, and no breach shall
            be excused unless such waiver or consent is provided in writing and
            signed by Kyno Health. Any consent or waiver by Kyno Health
            regarding your breach, whether expressed or implied, shall not
            constitute consent to, waiver of, or excuse for any other different
            or subsequent breach.
          </p>
          <p>
            This means that any waiver or consent to a specific breach of the
            Agreement must be explicitly documented and signed by Kyno Health.
            Such action does not imply forgiveness or acceptance of any other
            breaches that may occur in the future. Each breach will be assessed
            independently, and compliance with the Agreement will be strictly
            enforced unless formally waived in writing by Kyno Health.
          </p>
        </div>
      </section>
    </>
  );
}

export default index;
