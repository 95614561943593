import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Benefits of Regular Doctor Visits: Investing in Your Well-Being</h3>    
    <p>In the fast-paced world we live in, maintaining good health often takes a back seat to our hectic schedules and daily responsibilities. However, the importance of regular visits to the doctor cannot be overstated. Beyond just addressing specific health concerns, these visits play a vital role in preventive care, early detection of illnesses, and overall well-being. In this blog post, we'll explore the numerous benefits of making doctor visits a priority in your life.</p>


 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tags1Img'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
