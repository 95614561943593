import React from 'react'
import {Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';


function index() {

  return (
    <>

    <div className='main-menu'>
    <div className='container'>
    <nav className="navbar navbar-expand-lg navbar-light">
  <div className="container-fluid">
  <Link className="navbar-brand" to="#">
  <Image
      src={require('../Performance/images/head-logo.png')}
      webp={require('../Performance/images/head-logo.webp')}
      title='Kyno Health Logo'
      alt='headLogoImg'
    />
    {/* <img src="../images/head-logo.png" tittle="Kyno Health Logo" alt='Logo'/> */}
    </Link>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
     <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
    <div id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item">
        <Link className="nav-link" to="/">Home</Link>
        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/about-us">About Us</Link>        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>        
        </li>

        <li className="nav-item dropdown">
        <a href='/' className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'>Blog <span className="caret"></span></a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="nav-link dropdown-item" to="/blog/tips-finding-best-home-visit-doctors">Tips for Finding the Best Home Visit Doctors </Link>
                </li>
               
                <li>
                  <Link className="nav-link dropdown-item" to="/blog/top-10-benefits-home-visit-doctors">Top 10 Benefits of Home Visit Doctors </Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/tips-finding-best-home-visit-doctors">Tips for Finding the Best Home Visit Doctors</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/doctor-at-home-services-noida">Doctor At Home Services Noida</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/importance-of-doctor-appointments">Importance of Doctor Appointments</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/benefits-of-regular-doctor-visits">Benefits of Regular Doctor Visits</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/roles-of-a-general-physician">Role of General Physicians</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/tips-to-relief-from-high-fever">Tips to Relief Form High Fever</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/how-to-control-blood-pressure">How to Control Blood Pressure</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/what-is-diabetes">What is Diabetes</Link>
                </li>

                <li>
                  <Link className="nav-link dropdown-item" to="/blog/find-reliable-home-visit-doctors-guide">Find Reliable Home Visit Doctors Guide</Link>
                </li>

              </ul>
            </li>
            
        <li className="nav-item dropdown">
        <a href='/' className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'>Services <span className="caret"></span></a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-home-visit-noida">Doctors at Home in 60 mins</Link>
                </li>
                {/* <li>
                  <Link className="nav-link dropdown-item" to="/physician-near-me">Physician near me</Link>
                </li> */}
                <li>
                  <Link className="nav-link dropdown-item" to="/doctors-near-me">Best Doctors in Delhi - NCR</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/schedule-doctor-appointment">Appointment Scheduling</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/iv-therapy">IV Drip at Home</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/treatment-for-bed-sore">Bed Sore Treatment</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-on-call-home-visit">On-Call Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/twenty-four-hour-visiting-doctor">24*7 Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-for-fever-treatment">Doctors for Fever at Home</Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
                      <a href='/' className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'>General Physicians <span className="caret"></span></a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-alipur">Alipur</Link>
                        </li>

                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-civil-lines">Civil lines</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-defence-colony">Defence colony</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-at-home-in-gandhi-nagar-delhi">Gandhi nagar </Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-hauz-khas">Hauz khas</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-kalkaji">Kalkaji</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-kanjhawala">Kanjhawala</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-mehrauli">Mehrauli</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-model-town">Model town</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-narela">Narela</Link>
                        </li>

                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-at-home-preet-vihar-delhi">Preet vihar</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-saket">Saket</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-at-home-in-shahdara-delhi">Shahdara</Link>
                        </li>
                        <li>
                          <Link className="nav-link dropdown-item" to="/general-physicians-home-in-yamuna-vihar-delhi">Yamuna vihar</Link>
                        </li>
                      </ul>
                    </li>   

        {/* <li className="nav-item">
        <Link className="nav-link" to="/services">Services</Link> */}
        
        {/* </li> */}
        {/* <li className="nav-item">
        <Link className="nav-link" to="/meet_our_doctors">Services</Link>
        </li> */}
        <li className="nav-item butn">
        {/*<Link className="nav-link" to="/booking_form">Book a visit</Link> */} {/*Book Visit*/}
        <Link className="nav-link" to="tel:+919953104104">Call: 99531 04104</Link>
        </li>

        <li className="nav-item butn seo">
        <Link className="nav-link" to="tel:+919355618123">Call: 93556 18123</Link>      
        </li>

      </ul>
    </div>
    </div>
  </div>
</nav>
</div>
</div>
    </>
  )
}

export default index