import React from 'react'
// import {Link} from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Kyno Terms & Conditions</h3>    
    <p className='d-md-none d-lg-none'>Huami Technologies Private Limited, hereinafter referred to as "we," "us," and "Kyno Health," is both the author and publisher of the online resource located at https://www.kynohealth.com/, along with its associated subdomains (collectively referred to as the "Websites"). These Websites form a part of the broader World Wide Web. In addition to the Websites, Kyno Health also offers a range of connected software and applications, which include but are not limited to mobile applications (referred to as the "App"). Collectively, the Websites and the App are referred to as the "Services."
    </p>

<p className='mob-noee'>Huami Technologies Private Limited, hereinafter referred to as "we," "us," and "Kyno Health," is both the author and publisher of the online resource located at https://www.kynohealth.com/, along with its associated subdomains (collectively referred to as the "Websites"). These Websites form a part of the broader World Wide Web. In addition to the Websites, Kyno Health also offers a range of connected software and applications, which include but are not limited to mobile applications (referred to as the "App"). Collectively, the Websites and the App are referred to as the "Services."</p>

 {/* <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link> */}

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='../images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
