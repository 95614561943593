import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Understanding the Causes of Dandruff and Itchy Scalp: A Holistic View</h3>
    <p>Often, people get confused about dandruff and flaky scalp, assuming both are the same. Although both conditions involve the drying up of the scalp, they are distinct from each other. Hence, to understand better, you should have adequate knowledge about the causes of Dandruff and Itchy scalp, followed by the different treatment approaches. To better understand the underlying causes of dandruff and itchy scalp, get to reading this blog right away!</p>
<p>Also, if you are facing trouble managing your dandruff or itchy scalp, simply seek the assistance of the medical specialists at Kyno Health! Here you can avail the most reliable catered treatments for dry scalp dandruff and all other hair conditions with complete accountability.</p>
<p><strong>Also Read : </strong><a href="https://www.kynohealth.com/blog/best-dandruff-fighting-routine"><strong>The Best Dandruff Fighting Routine: Daily, Weekly, and Monthly Tips</strong></a></p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
