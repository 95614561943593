
import './Css/style.css'

function index() {
  return (
    <>

    <section className='sec_fold_abt'>
      <div className='container'>
            <h3>Address:</h3>
        <div className='row'>
          <div className='col-md-4'>
            <div className='text-center bs p-3 mb-2'>
            <p>HUAMI TECHNOLOGIES PRIVATE LIMITED <br/>
               H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001</p>
               </div>
          </div>

          <div className='col-md-4'>
          <div className='text-center bs p-3 mb-2'>
            <p>449/434/09 ,Behind Kanti Sweets, <br/> Bellandur Doddakannelli Road, Outer Ring Rd, <br/> Bengaluru, Karnataka 560103 <br/>&nbsp;</p>
          </div>
          </div>
          <div className='col-md-4'>
          <div className='text-center p-3 mb-2'>
            <p>Opp Unlimited Showroom, 501,<br/> Chinmaya Mission Hospital Rd, Indira Nagar, Stage 1,<br/> Bengaluru, Karnataka 560038 <br/> &nbsp;</p>
          </div>
          </div>
        </div>
            <p><strong>Business Hours: </strong>Monday to Saturday: 9:00 AM to 6:00 PM</p>
            <p><strong>General Inquiries/ Customer Support:</strong></p>
            <p>Email: <a href='mailto:support@kynohealth.com'>support@kynohealth.com</a></p>
            <p>Phone: <a href='tel:9953104104'>9953104104</a></p>

      </div>
    </section>
    
    </>
  )
}

export default index