import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What are the key advantages of hiring general physicians for home visits in Patel Nagar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are several advantages to hiring a general physician at home. First and foremost, you would not need to travel outside and spend hours in a hospital setup. Secondly, home visit consultations are detailed and you won't be asked to hurry for the person next in line. Thirdly, it is a huge advantage for elderly people, and new mothers who may be facing chronic conditions or have still not recovered from surgeries."
      }
    },{
      "@type": "Question",
      "name": "How can I find the best general physicians who offer home visits in Patel Nagar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can find the best general physician near me who offers home visits in Patel Nagar at Kyno Health. We are the first and top name when it comes to offering home visit doctors at affordable pricing. Our doctors are all highly qualified and the best in their respective fields. So, if you are looking for the best general physician in Patel Nagar, contact Kyno Health."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and experience should I look for in a general physician who provides home visits?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "While looking for qualifications and experiences, you should ask if the doctor is a board-certified physician from any recognized institute. As for the experiences, some may have 2-3 years of experience, while some may have 20+ years of experience. However, we can assure you that our home-visit doctors are thorough professionals and will provide you with the comfort of a “family doctor”."
      }
    },{
      "@type": "Question",
      "name": "While looking for qualifications and experiences, you should ask if the doctor is a board-certified physician from any recognized institute. As for the experiences, some may have 2-3 years of experience, while some may have 20+ years of experience. However, we can assure you that our home-visit doctors are thorough professionals and will provide you with the comfort of a “family doctor”.",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our general physicians offering home visits are proficient in offering a wide range of medical services. From IV treatments at home to getting treated for fevers or bed sores, trust in our doctors to provide you with your required service. We also provide diagnostic services such as X-ray, Ultrasound at home, or carrying out a blood test."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for a home visit in Patel Nagar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To schedule an appointment with our general physicians, feel free to reach out to us at this number: 9953104104. After that, our customer support will help you get started with a home visit appointment in Patel Nagar. You may also choose to mail us at this address in case you have any doubts-  support@kynohealth.com."
      }
    }]
  }
  


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Patel Nagar, Delhi</title>
        <meta name="description" content="Experience top-notch healthcare at home with Kyno Health's best general physicians in Patel Nagar, Delhi. Get personalized medical care without leaving your home." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-at-home-preet-vihar-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Patel Nagar, Delhi" hero_paragraph="In India, we have always had the tradition of having a family doctor. Carrying this tradition forward and combining it with modern-day medical innovations, Kyno Health provides medical services at home. With us, you can now book the Best General Physicians at Home in Patel Nagar, Delhi who will reach your home and offer you quality service within the comforts of your home. This is also a progressive step towards making medical services more inclusive. It is also drastically different from hospital services, where patients feel stifled by heavy expenses and bureaucratic services."/>
      
      <Content content_h3="How Are General Physicians' at Home Services Different From Hospital Services?" content_p="General physicians' at-home services offer a distinct and highly beneficial alternative to traditional hospital services. With Kyno Health, patients can receive personalized medical care in the comfort of their own homes. This reduces the stress and inconvenience often associated with hospital visits. " content_p1="This service is particularly advantageous for elderly patients and individuals with mobility issues. It can also be beneficial to those who prefer the privacy of their home environment. Additionally, home visits eliminate the long waiting times that are generally seen in a hospital. One also need not face the impersonal nature of hospital care. The cost-effectiveness of at-home services also means patients can avoid hefty expenses typically associated with hospitals." content_p3="Another significant advantage of at-home medical services is the ability to provide continuous care. Physicians can monitor patients in their everyday environments. This allows for a more accurate assessment of their health and lifestyle. Furthermore, family members can be present during consultations." 

      content_h3_2="Access Top-Quality Treatments from General Physicians of Kyno Health in Patel Nagar" content_p_2="Book top-quality treatments from Kyno Health's general physician home visit near me in Patel Nagar today. Experience exceptional care at home, ensuring comfort and convenience. Together, we can make a difference in medical care and services. Schedule an appointment today, and let us make a holistic impact on medical services. With Kyno Health, accessing quality and affordable medical care at your home in Patel Nagar is no longer a dream but a reality!" />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index