import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Top 10 Home Remedies for Effective Dandruff Treatment</h3>
    <p>Tired of managing your everlasting dandruff? Time to rely on the best home remedies! Are you looking forward to learning about the most effective and reliable natural dandruff remedies? This blog is your one-stop destination to learn about the most effective dandruff remedies of all time! Also, you can visit Kyno Health for advanced dandruff hair care and treatment!</p>
<p><strong>Also Read : </strong><a href="https://www.kynohealth.com/blog/causes-of-dandruff-and-itchy-scalp"><strong>Understanding the Causes of Dandruff and Itchy Scalp: A Holistic View</strong></a></p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
