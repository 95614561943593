import React, { useEffect, useState, useRef } from 'react'
import logo from "../../../utilities/images/logo.png"
import "../../../styles/patientInfoPage.scss"
import wa from "../../../utilities/images/dashboard/wa.png"
import location from "../../../utilities/images/dashboard/location.png"
import call from "../../../utilities/images/dashboard/call.png"
import Collapsible from "react-collapsible"
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import CurrentDateTime, { knowlarity_authorization_key, knowlarity_x_api_key, localBackend } from '../../../utilities/constants'
import Popup from 'reactjs-popup'
import Cookies from 'js-cookie'
import WAChatWindow from './WAChatWidow'
import Select from "react-select"

import edit from "../../../utilities/images/edit.png"


export default function PatientInfoPage() {

    const diseaseOptions = [
        {
            "label": "Loss of appetite",
            "value": "LOSS_OF_APPETITE"
        },
        {
            "label": "Body pain",
            "value": "BODY_PAIN"
        },
        {
            "label": "Stomach pain",
            "value": "STOMACH_PAIN"
        },
        {
            "label": "Loose Motions",
            "value": "LOOSE_MOTIONS"
        },
        {
            "label": "Tiredness and weakness",
            "value": "TIREDNESS_AND_WEAKNESS"
        },
        {
            "label": "High BP",
            "value": "HIGH_BP"
        },
        {
            "label": "Low BP",
            "value": "LOW_BP"
        },
        {
            "label": "Headache",
            "value": "HEADACHE"
        },
        {
            "label": "High Fever",
            "value": "HIGH_FEVER"
        },
        {
            "label": "Breathing difficulty",
            "value": "BREATHING_DIFFICULTY"
        },
        {
            "label": "Allergy Redness through the body, Itching",
            "value": "ALLERGY_REDNESS_ITCHING"
        },
        {
            "label": "Abdominal pain",
            "value": "ABDOMINAL_PAIN"
        },
        {
            "label": "Constipation",
            "value": "CONSTIPATION"
        },
        {
            "label": "Jaundice",
            "value": "JAUNDICE"
        },
        {
            "label": "Insomnia (Sleeplessness)",
            "value": "INSOMNIA"
        },
        {
            "label": "Edema (Swelling in body )",
            "value": "EDEMA_SWELLING"
        },
        {
            "label": "Vomiting",
            "value": "VOMITING"
        },
        {
            "label": "Minor bleeding from any body part",
            "value": "MINOR_BLEEDING"
        },
        {
            "label": "High or low Sugar",
            "value": "HIGH_LOW_SUGAR"
        },
        {
            "label": "Unconcious or unresponsive",
            "value": "UNCONSCIOUS"
        },
        {
            "label": "Unables to pass urine",
            "value": "UNABLES_PASS_URINE"
        },
        {
            "label": "Dizziness",
            "value": "DIZZINESS"
        },
        {
            "label": "Chills/Sweats",
            "value": "CHILLS_SWEATS"
        },
        {
            "label": "Cough and cold",
            "value": "COUGH_COLD"
        },
        {
            "label": "Sore Throat",
            "value": "SORE_THROAT"
        },
        {
            "label": "Burning while urination",
            "value": "BURNING_URINATION"
        },
        {
            "label": "Fungal infections / Skin issues",
            "value": "FUNGAL_INFECTIONS"
        },
        {
            "label": "Minor Wound Dressing",
            "value": "MINOR_WOUND_DRESSING"
        },
        {
            "label": "Animal Bite",
            "value": "ANIMAL_BITE"
        },
        {
            "label": "Insect bite",
            "value": "INSECT_BITE"
        },
        {
            "label": "To Administer IM Or IV Inj",
            "value": "ADMINISTER_INJECTION"
        },
        {
            "label": "Pain Management",
            "value": "PAIN_MANAGEMENT"
        },
        {
            "label": "PC Enema",
            "value": "PC_ENEMA"
        },
        {
            "label": "Ear ache",
            "value": "EAR_ACHE"
        },
        {
            "label": "Uncertainity or fracture",
            "value": "UNCERTAINITY_FRACTURE"
        },
        {
            "label": "Seizure/Fits",
            "value": "SEIZURE_FITS"
        },
        {
            "label": "Back pain",
            "value": "BACK_PAIN"
        },
        {
            "label": "Sports Injury",
            "value": "SPORTS_INJURY"
        },
        {
            "label": "Minor Burns",
            "value": "MINOR_BURNS"
        },
        {
            "label": "Chest pain",
            "value": "CHEST_PAIN"
        },
        {
            "label": "Knee Pain",
            "value": "KNEE_PAIN"
        },
        {
            "label": "Throat Infection",
            "value": "THROAT_INFECTION"
        },
        {
            "label": "General Checkup",
            "value": "GENERAL_CHECKUP"
        },
        {
            "label": "Anxiety",
            "value": "ANXIETY"
        },
        {
            "label": "Lump on skin",
            "value": "LUMP_ON_SKIN"
        },
        {
            "label": "Old Age issues",
            "value": "OLD_AGE_ISSUES"
        },
        {
            "label": "Muscle Pain",
            "value": "MUSCLE_PAIN"
        },
        {
            "label": "Medical Certificate",
            "value": "MEDICAL_CERTIFICATE"
        },
        {
            "label": "Dental Pain",
            "value": "DENTAL_PAIN"
        },
        {
            "label": "Depression",
            "value": "DEPRESSION"
        },
        {
            "label": "Stone",
            "value": "STONE"
        },
        {
            "label": "Pelvic cramps",
            "value": "PELVIC_CRAMPS"
        },
        {
            "label": "Injury",
            "value": "INJURY"
        },
        {
            "label": "Abscess or I&D",
            "value": "ABSCCESS_I_D"
        },
        {
            "label": "Post Surgery care",
            "value": "POST_SURGERY_CARE"
        },
        {
            "label": "Low Oxygen Level",
            "value": "LOW_OXYGEN_LEVEL"
        },
        {
            "label": "Fitness Certificate",
            "value": "FITNESS_CERTIFICATE"
        },
        {
            "label": "Thyroid",
            "value": "THYROID"
        },
        {
            "label": "Heart Related issues",
            "value": "HEART_RELATED_ISSUES"
        },
        {
            "label": "Joint Pain",
            "value": "JOINT_PAIN"
        },
        {
            "label": "Acidity",
            "value": "ACIDITY"
        },
        {
            "label": "Gyne Issue",
            "value": "GYNE_ISSUE"
        },
        {
            "label": "High Oxygen Level",
            "value": "HIGH_OXYGEN_LEVEL"
        }
    ]


    const [pid] = useSearchParams()
    const SE = Cookies.get("salesEmployee")
    const navigate = useNavigate()
    // console.log(SE)
    const [patientId, setPatientId] = useState(pid.get("pid").substring(3))
    const [middleTabSelected, setMiddleTabSelected] = useState("ACTIVITY")
    const [patientSelected, setPatientSelected] = useState("")
    const [patient, setPatient] = useState(null)
    const [patientFamily, setPatientFamily] = useState(null)
    const [latestLead, setLatestLead] = useState(null)
    const [activeLeads, setActiveLeads] = useState(null)
    const [leads, setLeads] = useState(null)
    const [doctors, setdoctors] = useState(null)
    const [salesEmployees, setSalesEmployees] = useState(null)

    const [addressSelected, setAddressSelected] = useState("");
    const [newAddress, setNewAddress] = useState("");
    const [newPatientName, setNewPatientName] = useState();
    const [bookAppType, setBookAppType] = useState("HOME_CONSULTATION");
    const [bookOpdType, setBookOpdType] = useState("NEW_CONSULTATION");
    const [bookDiseaseType, setBookDiseaseType] = useState("COUGH_AND_COLD");
    const [bookDoctor, setBookDoctor] = useState("DOCTOR");
    const [bookCashCollected, setBookCashCollected] = useState(1000);
    const [bookDate, setBookDate] = useState("");
    const [bookSlot, setBookSlot] = useState(-1)

    const [bookAppointmentClicked, setBookAppointmentClicked] = useState(false)


    const [personaldetailname, setpersonaldetailname] = useState("");
    const [personaldetailnumber, setpersonaldetailnumber] = useState("")
    const [personaldetailaddress, setpersonaldetailaddress] = useState("")
    const [personaldetailage, setpersonaldetailage] = useState("")
    const [personaldetailgender, setpersonaldetailgender] = useState("")
    const [personaldetailoccupation, setpersonaldetailoccupation] = useState("")
    const [personaldetailcity, setpersonaldetailcity] = useState("")

    const [localities, setLocalities] = useState(null)
    const [modalMsg, setModalMsg] = useState("")
    const [pageMsg, setPageMsg] = useState("")

    const [comments, setComments] = useState(null)


    ///       reschedule cancel popup states

    const [rescheduleCancelType, setRescheduleCancelType] = useState("")

    const appointmentCancellationReason = useRef()
    const appointmentRescheduleReason = useRef()
    const selectRescheduleSlot = useRef()
    const selectRescheduleDate = useRef()

    ///       reschedule cancel popup states

    const [allTransactions, setAllTransactions] = useState(null)

    const handleDiseaseMemberChange = (doptions) => {
        setEditFamilyMemberDetail(prevState => ({
            ...prevState, "diseaseType": doptions
        }))
    }
    const handleDiseaseNewMemberChange = (doptions) => {
        setNewFamilyMember(prevState => ({
            ...prevState, "diseaseType": doptions
        }))
    }


    //////////////////////////////////////////////////////////////////////
    /////////// Attachments states and functions /////////////////////////

    const [selectedAttachmentForUpload, setSelectedAttachmentForUpload] = useState(null)
    const fileNameForUpload = useRef()
    const fileTypeForUpload = useRef()

    const [patientAttachments, setPatientAttachments] = useState(null)

    const onAttachmentFileChange = (event) => {
        setSelectedAttachmentForUpload({
            selectedFile: event.target.files[0]
        })
        console.log(event.target.files[0])
    }

    const onFileUpload = async () => {

        if ((fileNameForUpload.current.value !== "" && (selectedAttachmentForUpload.selectedFile.name.split(".")[1] === fileNameForUpload.current.value.split(".")[1])) || fileNameForUpload.current.value == "") {


            const formDate = new FormData()

            // const fileExtension = selectedAttachmentForUpload.selectedFile.name.split('.').pop();
            // const newFileName = `${selectedAttachmentForUpload.selectedFile.name.split(".")[0]}.${fileExtension}`;
            // const newFileName = `file.${fileExtension}`;

            formDate.append(
                "fileName", fileNameForUpload.current.value === "" ? selectedAttachmentForUpload.selectedFile.name : `${fileTypeForUpload.current.value}${fileNameForUpload.current.value}`
            )
            formDate.append(
                "file", selectedAttachmentForUpload.selectedFile
            )
            formDate.append(
                "fileFor", "Testing"
            )

            formDate.append(
                "patientId", `91${patientId}`
            )
            console.log(formDate)

            let headers = {
                'Content-Type': 'multipart/form-data',
            }

            const result = await axios.post(`${localBackend}uploadAttachment`, formDate, { headers: headers }).then((resp) => {
                console.log(resp)
                if (resp.data.statusCode === 1) {
                    setSelectedAttachmentForUpload(null)
                }
                window.location.reload()
                setmodalmessage(resp.data.data)
            })
        } else {
            setPageMsg("File extension in New Name do not match original")
        }

    }


    const getPatientFiles = async () => {
        const result = await axios.get(`${localBackend}getPatientAttachments?patientPhone=${patientId}&salesEmployeeId=${SE}`).then((resp) => {
            console.log(resp.data.data)
            setPatientAttachments(resp.data.data)

        })
    }


    // const sendAttachmentToPatient = async (attachmentId) => {

    // }

    const downloadFile = async (attachmentId) => {
        const downloadUrl = `https://drive.google.com/uc?export=download&id=${attachmentId}`;
        window.open(downloadUrl, '_blank');
    }

    /////////// Attachments states and funtions ends here ////////////////
    //////////////////////////////////////////////////////////////////////




    const commentInput = useRef()

    const familyMemberDetailsTemplate = {
        name: "",
        age: "",
        relation: "",
        gender: "",
        address: "",
        diseaseType: []
    }

    const [editFamilyMemberDetail, setEditFamilyMemberDetail] = useState({
        name: "",
        age: "",
        relation: "",
        gender: "",
        address: "",
        diseaseType: []
    })
    const [newFamilyMember, setNewFamilyMember] = useState({
        name: "",
        age: "",
        relation: "",
        gender: "",
        address: "",
        diseaseType: []
    })




    const fetchPatientInfo = async () => {
        const result = await axios.get(`${localBackend}fetchPatientInfo?id=${[patientId]}`).then((resp) => {
            // console.log(resp.data.data)
            if (resp.data.statusCode === 1) {
                setPatient(resp.data.data.patient)
                setPatientFamily(resp.data.data.patientFamily)
                let p = resp.data.data.patient;

                setpersonaldetailname(p.name);
                setpersonaldetailnumber(p.phone);
                setpersonaldetailaddress(p.address);
                setpersonaldetailage(p.age);
                setpersonaldetailgender(p.gender);
                setpersonaldetailoccupation(p.occupation);
                setpersonaldetailcity(p.city)
                // console.log(p.city)


                // const leads = resp.data.data.leads[0]
                // setLatestLead(leads[0][0])
                return resp.data.data.patient
            }
        })
        return result
    }

    const getLocalityName = (id) => {
        var obj = localities.find(l => l._id === id) || null;
        if (obj === null) {
            return "";
        } else {
            return obj.name
        }
    }


    const fetchLeads = async () => {
        const result = await axios.get(`${localBackend}fetchPatientLeads?patientId=${[patientId]}`).then((resp) => {
            if (resp.data.statusCode === 1) {
                // console.log(resp.data.data)
                const leads = resp.data.data.leads;
                const bookedAppontments = resp.data.data.bookedAppointments;
                setLatestLead(resp.data.data.leads[0])
                setLeads(resp.data.data.leads)
                setActiveLeads(resp.data.data.bookedAppointments)
                console.log(resp.data.data.bookedAppointments)
            }
        })
    }

    const onBookApppointmentClick = async (close) => {
        console.log("Booking Appointment")
        if (bookAppointmentClicked === false && (((patientSelected === "NEW_PATIENT" && newPatientName !== "") || (patientSelected !== "NEW_PATIENT" && patientSelected !== "")) && (bookDoctor !== "") && bookDate !== "" && bookSlot !== "" && ((addressSelected === "NEW_ADDRESS" && newAddress !== "") || (addressSelected !== "NEW_ADDRESS" && addressSelected !== "")))) {
            setBookAppointmentClicked(true)
            let p = patientSelected == "NEW_PATIENT"
            let a = addressSelected == "NEW_ADDRESS" ? newAddress : addressSelected;
            // console.log("Booking appointment: ", patientSelected, newPatientName, bookAppType, bookOpdType, bookDiseaseType, bookCashCollected, bookDoctor, bookDate, a)
            let body = {
                isNewPatient: p,
                familyMemberId: patientSelected,
                newFamilyMemberName: newPatientName,
                appointmentType: bookAppType,
                opdType: bookOpdType,
                diseaseType: bookDiseaseType,
                cashToBeCollected: bookCashCollected,
                doctorId: bookDoctor,
                appointmentDate: bookDate,
                appointmentSlot: bookSlot,
                patientPhone: patientId,
                currentSalesEmployeeId: SE,
                patientAddress: a
            }

            // console.log(body)
            const result = await axios.post(`${localBackend}bookAppointment`, body).then((resp) => {
                console.log(resp)
                setPopUpModalMsg(resp.data.data)
                if (resp) {
                    let elem = document.getElementById("root")
                    console.log(elem.blur())
                    close()
                    setBookAppointmentClicked(false)
                }
                if (resp.data.statusCode === 1) {
                    window.location.reload()
                }
            })
        } else {
            setPopUpModalMsg("Something is Wrong")
        }
    }


    const onUpdatePersonalInfoClicked = async () => {
        // console.log(personaldetailname, personaldetailnumber, personaldetailaddress, personaldetailage, personaldetailgender, personaldetailoccupation, personaldetailcity)
        let body = {
            name: personaldetailname == undefined ? "" : personaldetailname,
            phone: personaldetailnumber == undefined ? "" : personaldetailnumber,
            address: personaldetailaddress == undefined ? "" : personaldetailaddress,
            gender: personaldetailgender == undefined ? "" : personaldetailgender,
            age: personaldetailage == undefined ? "" : personaldetailage,
            occupation: personaldetailoccupation == undefined ? "" : personaldetailoccupation,
            city: personaldetailcity == undefined ? "" : personaldetailcity,
            salesEmployeeId: SE
        }

        console.log(body)

        const result = await axios.post(`${localBackend}updatePatientPersonalInfo`, body).then((resp) => {
            console.log(resp.data)
            if (resp.data.statusCode === 1) {
                setmodalmessage("Patient Info Updated Successfully")
            } else {
                setmodalmessage("Patient Info Update Failed")
            }
        })
    }
    const fetchLocalities = async (patient) => {
        const res = await axios.get(`${localBackend}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                // console.log("Localities: ", resp.data.data)
                // var obj = resp.data.data.find(l => l._id === patient.city) || null;
                // var lid = obj === null ? "" : obj.name
                // console.log
                // setpersonaldetailcity(lid)

                return resp.data.data
            }
        })
    }

    const getPatientFamilyMemberName = (id) => {
        var obj = patientFamily.find(l => l._id == id) || null;
        var name = obj === null ? "" : obj.name
        return name
    }

    const getdoctornamebyid = (id) => {
        var obj = doctors.find(d => d._id == id) || null;
        var name = obj === null ? "" : obj.name;
        return name
    }

    const getSalesEmployeeName = (id) => {
        // console.log(salesEmployees)
        var obj = salesEmployees.find(d => d._key == id) || null;
        var name = obj === null ? "" : obj.name;
        return name
    }
    const getSalesEmployeeNumber = (id) => {
        var obj = salesEmployees.find(d => d._key == id ) || null;
        var number = obj === null ? "" : obj.phone;
        return number
    }

    const fetchdoctors = async () => {
        const result = await axios.get(`${localBackend}allDoctors`).then((resp) => {
            // console.log(resp.data.data)
            setdoctors(resp.data.data)
        })
    }

    const fetchAllSalesEmployee = async () => {
        const result = await axios.get(`${localBackend}getAllSalesEmployee?salesEmployeeId=${SE}`).then((resp) => {
            // console.log(resp.data)
            setSalesEmployees(resp.data.data)
        })
    }

    const fetchPatientComments = async () => {
        const result = await axios.get(`${localBackend}getPatientComments?patientKey=${patientId}&salesEmployeeId=${SE}`).then((resp) => {
            // console.log(resp.data)
            setComments(resp.data.data)
        })
    }


    const onCommentSubmitClick = async () => {

        console.log(commentInput.current.value)
        let body = {
            comment: commentInput.current.value,
            createdBy: SE,
            employeeType: "SALES",
            createdOn: CurrentDateTime(),
            patientId: patientId
        }

        const result = await axios.post(`${localBackend}createComment`, body).then((resp) => {
            console.log(resp.data)

            return resp.data.statusCode
        })

        if (result === 1) {
            fetchPatientComments();
        }
    }

    const setmodalmessage = (msg) => {
        setPageMsg(msg)
        setInterval(() => {
            setPageMsg("")
        }, 2000)
    }

    const setPopUpModalMsg = (msg) => {
        setModalMsg(msg)
        setInterval(() => {
            setModalMsg("")
        }, 2000)
    }


    const loginChat360 = async () => {
        if (Cookies.get("chat360-cookie") === undefined) {
            let body = {
                "email": "Aditya@kynohealth.com",
                "password": "Huami@1000"
            }
            const result = await axios.post("https://app.chat360.io/api/auth/login", body).then((resp) => {
                console.log(resp.data)
            })
        }
    }

    // const waClicked = async () => {
    //     console.log("WA Clicked")

    //     const headers = {
    //         "Authorization": "Api-Key lV6VAEc2.Btj1USjd9wnkDGFNINu1ecBiNTMcE6WS",
    //         "Content-Type": "application/json"
    //     }

    //     const data = {
    //         "task_name": "whatsapp_push_notification",
    //         "extra": "",
    //         "task_body": [
    //             {
    //                 "client_number": "919953104104", // Chat360 business number
    //                 "receiver_number": "918306304683", // number of reciever
    //                 "template_data": {
    //                     "template_id": "a783ae0b-3ba2-4beb-bc08-3a482f516c5a",
    //                     "param_data": {},
    //                     "button_param_data": {}
    //                 }
    //             }
    //         ]
    //     }
    //     const result = await axios.post("https://app.chat360.io/service/v1/task", data, { headers }).then((resp) => {
    //         console.log(resp.data)
    //     })
    // }

    const fetchAllTransactions = async () => {
        const result = await axios.get(`${localBackend}allPatientTransactions?patientId=${patientId}&salesEmployeeId=${SE}`).then((resp) => {
            console.log(resp.data)
            if (resp.data.statusCode === 1) {
                setAllTransactions(resp.data.data)
            }
        })
    }


    const sendPaymentMessage = async (patientName, transactionId) => {
        let body = {
            patientId: patientId,
            patientName: patientName,
            transactionId: transactionId,
            salesEmployeeId: SE
        }
        const result = await axios.post(`${localBackend}sendPaymentMessage`, body).then((resp) => {
            console.log(resp.data)
            setmodalmessage(resp.data.data)

            if (resp.data.statusCode === 1) {
                fetchAllTransactions()
            }
        })
    }


    const setPaymentDone = async (transactionId) => {
        let body = {
            transactionId: transactionId,
            salesEmployeeId: SE
        }

        const result = await axios.post(`${localBackend}setPaymentDone`, body).then((resp) => {
            console.log(resp)
            setmodalmessage(resp.data.data)
            if (resp.data.statusCode === 1) {
                fetchAllTransactions()
            }
        })
    }

    const onAppointmentCancelClick = async (appointmentId, close) => {
        let body = {
            salesEmployeeId: SE,
            appointmentId: appointmentId,
            cancelReason: appointmentCancellationReason.current.value
        }

        const result = await axios.post(`${localBackend}cancelAppointment`, body).then((resp) => {
            console.log(resp.data)
            setmodalmessage(resp.data.data)
            if (resp.data.statusCode === 1) {
                window.location.reload()
            } else {
                close()
            }
        })
    }

    const onRescheduleAppointmentClicked = async (appointmentId, close) => {
        let body = {
            salesEmployeeId: SE,
            appointmentId: appointmentId,
            rescheduleReason: appointmentRescheduleReason.current.value,
            date: selectRescheduleDate.current.value,
            slot: selectRescheduleSlot.current.value
        }
        // console.log(body)

        const result = await axios.post(`${localBackend}rescheduleAppointment`, body).then((resp) => {
            console.log(resp.data)
            setmodalmessage(resp.data.data)
            if (resp.data.statusCode === 1) {
                window.location.reload()
            } else {
                close()
            }
        })
    }


    const createCallToPatient = async () => {
        let headers = {
            "x-api-key": knowlarity_x_api_key,
            "Authorization": knowlarity_authorization_key,
            "content-type": "application/json"
        }

        let _sn = getSalesEmployeeNumber(SE)
        let body = {
            "k_number": "+918068323164",
            "agent_number": `${_sn}`,
            "customer_number": `+91${patientId}`
        }
        console.log(body, headers, "calling patient and bd")
        const result = await axios.post("https://kpi.knowlarity.com/Basic/v1/account/call/makecall", body, headers={headers}).then((resp) => {
            console.log(resp.data)

            if(resp.data.success.status !== 'success'){
                setmodalmessage("call not sent")
            }else{
                setmodalmessage("Call Sent")
            }


        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        const patient = fetchPatientInfo()
        fetchLocalities(patient)
        fetchLeads()
        fetchdoctors()
        fetchAllSalesEmployee()
        fetchAllTransactions()
        fetchPatientComments()
        getPatientFiles()
        // loginChat360()
    }, [])

    return (
        <>
            <div className='patient-info-page'>
                <div className='title'>
                    <img src={logo} onClick={(e) => {
                        navigate("/dashboard")
                    }} />
                    <div className='sales-db-back' onClick={() => {
                        if (pid.get("back") == "sales-dashboard-appointment") {
                            navigate("/dashboard/sales#appointments")
                        } else {
                            navigate("/dashboard/sales")
                        }
                    }}>Go Back</div>
                </div>

                <div className='patient-info'>
                    <div className='left'>
                        {patient !== null &&
                            <div className='basic-details'>
                                <div className='name'>{patient.name === "" ? "[no-name]" : patient.name}</div>
                                <div className='phone'>{patient.phone}</div>
                                <div className='city'>City: Delhi</div>
                                <div className='connect-btns'>
                                    <img src={call} onClick={(e) => {
                                        createCallToPatient()
                                    }} />
                                    <img src={wa} onClick={(e) => {

                                    }} />
                                    {/* <Popup trigger={
                                        <img src={wa} />
                                    } modal nested >{(closeWaPopup) => {
                                        return (
                                            <WAChatWindow salesEmployeeId={SE} patientNumber={patientId} />
                                        )
                                    }}</Popup> */}
                                    <img src={location} />
                                </div>
                            </div>
                        }
                        <Collapsible open={true} trigger={<div className='lead-info-title'>
                            Lead Info
                        </div>}>
                            {latestLead !== null && patient !== null && salesEmployees !== null &&
                                <div className='lead-info'>
                                    <div>Lead ID: {latestLead._key}</div>
                                    <div>Lead Creation Date: {latestLead.createdOn.split("T")[0]}</div>
                                    <div>Patient ID: {patient._id}</div>
                                    <div>Marketing Source: {latestLead.source}</div>
                                    <div>Lead Source: Call</div>
                                    <div>Current Sales Rep: {getSalesEmployeeName(latestLead.currentSalesEmployee)}</div>
                                    <div>Previous Sales Rep: {getSalesEmployeeName(latestLead.lastSalesEmployee)}</div>
                                </div>
                            }
                        </Collapsible>
                        <Collapsible trigger={<div className='personal-info-title'>
                            Personal Details
                        </div>}>
                            {patient !== null &&
                                <div className='personal-info'>
                                    <div>Name: <input type="text" value={personaldetailname} onChange={(e) => {
                                        setpersonaldetailname(e.target.value)
                                    }} /></div>
                                    <div>Mobile Number: {patient.phone}</div>
                                    <div>Address: <input type='text' value={personaldetailaddress} onChange={(e) => {
                                        setpersonaldetailaddress(e.target.value)
                                    }} /></div>
                                    <div>Age: <input type="text" value={personaldetailage} onChange={(e) => {
                                        setpersonaldetailage(e.target.value)
                                    }} /></div>
                                    <div>Gender:
                                        <select value={personaldetailgender} onChange={(e) => {
                                            setpersonaldetailgender(e.target.value)
                                        }} >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                    <div>Occupation: <input type="text" value={personaldetailoccupation} onChange={(e) => {
                                        setpersonaldetailoccupation(e.target.value)
                                    }} /> </div>
                                    <div>Zone:
                                        <select value={personaldetailcity} onChange={(e) => {
                                            setpersonaldetailcity(e.target.value)
                                        }}>
                                            <option key={-1} value="">Select Zone</option>
                                            {localities !== null &&
                                                localities.map((block, index) => (
                                                    <option key={index} value={block._id}>{block.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className='update' onClick={(e) => {
                                        onUpdatePersonalInfoClicked()
                                    }}>Update</div>
                                </div>
                            }
                        </Collapsible>
                        <Collapsible trigger={
                            <div className='family-info-title'>Family</div>
                        } >
                            <div className='family-info'>
                                <Popup modal nested className='add-member' trigger={
                                    <div className='add-member-link'>+ Add  Member</div>
                                }>{(close) => {

                                    return (
                                        <div className='add-member-modal'>
                                            <div className='title'>Add Family Member</div>


                                            <div className='_head'>Name</div>
                                            <input type='text' onChange={(e) => {
                                                setNewFamilyMember(f => ({
                                                    ...f, name: e.target.value
                                                }))
                                            }} />

                                            <div className='_head'>Age</div>
                                            <input type='number' onChange={(e) => {
                                                setNewFamilyMember(f => ({
                                                    ...f, age: e.target.value
                                                }))
                                            }} />
                                            <div className='_head'>Gender</div>
                                            <select onChange={(e) => {
                                                setNewFamilyMember(f => ({
                                                    ...f, gender: e.target.value
                                                }))
                                            }} >
                                                <option value="">Select Gender</option>
                                                <option value="MALE">Male</option>
                                                <option value="FEMALE">Female</option>
                                            </select>
                                            <div className='_head'>Relation</div>
                                            <input onChange={(e) => {
                                                setNewFamilyMember(f => ({
                                                    ...f, relation: e.target.value
                                                }))
                                            }}  />
                                            <div className='_head'>Address</div>
                                            <input onChange={(e) => {
                                                setNewFamilyMember(f => ({
                                                    ...f, address: e.target.value
                                                }))
                                            }} />
                                            <div className='_head'>Disease History</div>
                                            <Select className='disease-selector'
                                                options={diseaseOptions}
                                                value={setNewFamilyMember.diseaseType}
                                                onChange={handleDiseaseNewMemberChange}
                                                isMulti={true}
                                            />

                                            <div className='submit' onClick={async (e) => {
                                                let body = {
                                                    body: newFamilyMember,
                                                    phone: patientId,
                                                    salesEmployeeId: SE
                                                }
                                                console.log(body)
                                                
                                                const result = await axios.post(`${localBackend}newFamilyMember`, body).then((resp) => {
                                                    if (resp.data.statusCode == 1) {
                                                        window.location.reload()
                                                    }
                                                })
                                            }}> Submit</div>



                                        </div>
                                    )
                                }}</Popup>
                                {patientFamily !== null &&
                                    patientFamily.map((member, index) => (
                                        <div key={index} className='patient-family'>
                                            <Popup modal nested className='edit-patient-family-member' trigger={
                                                <div className='edit'><img src={edit} alt='edit' /></div>
                                            } onOpen={() => {
                                                setEditFamilyMemberDetail(member)
                                            }}>
                                                {(close) => {
                                                    return (

                                                        <div className='content'>
                                                            <div className='title'>Edit Patient Details</div>
                                                            <div className='_head'>Name</div>
                                                            <input type='text' onChange={(e) => {
                                                                member.name = e.target.value
                                                                setEditFamilyMemberDetail(f => ({
                                                                    ...f, name: e.target.value
                                                                }))
                                                            }} value={member.name} />

                                                            <div className='_head'>Age</div>
                                                            <input type='number' onChange={(e) => {
                                                                member.age = e.target.age
                                                                setEditFamilyMemberDetail(f => ({
                                                                    ...f, age: e.target.value
                                                                }))
                                                            }} value={member.age} />
                                                            <div className='_head'>Gender</div>
                                                            <select onChange={(e) => {
                                                                member.gender = e.target.value
                                                                setEditFamilyMemberDetail(f => ({
                                                                    ...f, gender: e.target.value
                                                                }))
                                                            }} value={member.gender}>
                                                                <option value="">Select Gender</option>
                                                                <option value="MALE">Male</option>
                                                                <option value="FEMALE">Female</option>
                                                            </select>
                                                            <div className='_head'>Relation</div>
                                                            <input onChange={(e) => {
                                                                member.relation = e.targer.value
                                                                setEditFamilyMemberDetail(f => ({
                                                                    ...f, relation: e.target.value
                                                                }))
                                                            }} value={member.relation} />
                                                            <div className='_head'>Address</div>
                                                            <input onChange={(e) => {
                                                                member.address = e.target.value
                                                                setEditFamilyMemberDetail(f => ({
                                                                    ...f, address: e.target.value
                                                                }))
                                                            }} value={member.address} />
                                                            <div className='_head'>Disease History</div>
                                                            <Select className='disease-selector'
                                                                options={diseaseOptions}
                                                                value={editFamilyMemberDetail.diseaseType}
                                                                onChange={handleDiseaseMemberChange}
                                                                isMulti={true}
                                                            />

                                                            <div className='submit' onClick={async (e) => {
                                                                console.log(editFamilyMemberDetail)
                                                                let body = {
                                                                    body: editFamilyMemberDetail,
                                                                    salesEmployeeId: SE
                                                                }

                                                                const result = await axios.post(`${localBackend}editFamilyMember`, body).then((resp) => {
                                                                    if (resp.data.statusCode == 1) {
                                                                        window.location.reload()
                                                                    }
                                                                })
                                                            }}> Submit</div>

                                                        </div>
                                                    )
                                                }}

                                            </Popup>
                                            <div className='name'>
                                                <span>{member.name}</span>
                                                <span>({member.relation})</span>
                                            </div>
                                            <div className='age-gender'>
                                                <span>{member.age} Y</span>
                                                <span>{member.gender}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Collapsible>

                        <div className='page-msg'>{pageMsg}</div>
                    </div>
                    <div className='middle'>
                        <div className='tabs'>
                            <div className={middleTabSelected === "ACTIVITY" ? "active" : ""} onClick={() => { setMiddleTabSelected("ACTIVITY") }} >Activity</div>
                            <div className={middleTabSelected === "NOTES" ? "active" : ""} onClick={() => { setMiddleTabSelected("NOTES") }} >Notes</div>
                            <div className={middleTabSelected === "TRANSACTIONS" ? "active" : ""} onClick={() => { setMiddleTabSelected("TRANSACTIONS") }} >Transactions</div>
                            <div className={middleTabSelected === "ATTACHMENTS" ? "active" : ""} onClick={() => { setMiddleTabSelected("ATTACHMENTS") }} >Attachments</div>
                        </div>


                        <div className='middle-content'>
                            {middleTabSelected === "ACTIVITY" &&
                                <div className='activities'>
                                    <div className='activity'>
                                        <div className='all-leads'>
                                            <div className='title'>All Activity</div>
                                            <div className='content'>
                                                {leads !== null && patientFamily !== null && doctors !== null && salesEmployees !== null && leads.map((block, index) => {
                                                    // console.log(block)
                                                    if (block !== null) {
                                                        let pname = block.name;
                                                        let psource = block.source;
                                                        let pdate = block.createdOn.split("T")[0]
                                                        let ptime = block.createdOn.split("T")[1].split(".")[0]
                                                        let pconcern = block.concern
                                                        // let pname = getPatientFamilyMemberName(block.patientId)
                                                        // let pdname = getdoctornamebyid(block.doctorId)
                                                        // let psname = getSalesEmployeeName(block.appointmentBookedBy);
                                                        // let pdate = block.appointmentBookedDate.split("T")[0];
                                                        // let ptime = pdate.split(".")[0]
                                                        // console.log(pname, pdname, psname, pdate, ptime)
                                                        return (
                                                            <div key={index} className='lead'>
                                                                <div>Lead Created by {pname == "" ? "[no-name]" : pname} through {psource} regarding {pconcern == "" ? "[no-concern]" : pconcern} </div>
                                                                <div>{pdate}, {ptime} </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className='active-leads'>
                                            <div className='title'>All Booked Appointments</div>
                                            <div className='content'>
                                                {activeLeads !== null && patientFamily !== null && doctors !== null && salesEmployees !== null && activeLeads.map((block, index) => {
                                                    if (block !== null) {
                                                        let pname = getPatientFamilyMemberName(block.patientId)
                                                        let pdname = getdoctornamebyid(block.doctorId)
                                                        let psname = getSalesEmployeeName(block.appointmentBookedBy);
                                                        let pdate = block.appointmentBookedDate.split("T")[0];
                                                        let ptime = block.appointmentBookedDate.split("T")[1].split(".")[0]
                                                        // console.log(pname, pdname, psname, pdate, ptime)
                                                        return (
                                                            <div key={index} className={`lead ${block.status === "CANCELLED" ? "cancelled" : ""} ${block.status === "RESCHEDULED" ? "rescheduled" : ""}`}>
                                                                {/* <div>Appointment Booked for {pname} through website with {pdname}  </div>
                                                                <div>Updated By {psname}: {pdate}, {ptime} </div> */}

                                                                <div className='_left'>
                                                                    <div>Appointment Type: {block.serviceType}</div>
                                                                    <div>Doctor Name: {pdname}</div>
                                                                    <div>Amount To Be Collected: {block.amountToBeCollected}</div>
                                                                    <div>Payment Status: {block.transaction !== null ? block.transaction.status : ""}</div>
                                                                    <div>Appointment Date: {block.appointmentDate}</div>
                                                                    {/* <div>Payment Status: {block.transaction.status}</div> */}
                                                                </div>

                                                                <div className='_right'>
                                                                    <div>Appointment Created By: {psname}</div>
                                                                    <div>Appointment Booked Date: {pdate}</div>
                                                                    <div>Appointment Booked Time : {ptime}</div>
                                                                </div>

                                                                <div className='edit-option' onClick={(e) => {
                                                                    navigate(`/dashboard/patient-book-appointment?pid=+91${patientId}&se=${SE}&type=edit&id=${block._id}`)
                                                                }}>Edit</div>

                                                                <Popup className='reschedule-cancel-popup' trigger={
                                                                    <div className='options'>Reschedule/Cancel</div>
                                                                } modal nested>{(close) => (
                                                                    <div className='reschedule-cancel'>
                                                                        <div className='title'>Reschedule/Cancel Appointment</div>
                                                                        <div className='content'>
                                                                            <div className='head'>Select Request Type</div>
                                                                            <select value={rescheduleCancelType} onChange={(e) => {
                                                                                setRescheduleCancelType(e.target.value)
                                                                            }}>
                                                                                <option value={""}>Select Option</option>
                                                                                <option value={"RESCHEDULE"}>Reschedule Appointment</option>
                                                                                <option value={"CANCEL"}>Cancel Appointment</option>
                                                                            </select>

                                                                            {rescheduleCancelType === "RESCHEDULE" &&
                                                                                <div className='reschedule'>
                                                                                    <div>
                                                                                        Appointment Reschedule Reason
                                                                                    </div>
                                                                                    <textarea ref={appointmentRescheduleReason} className='reschedule-reason' />

                                                                                    <div className='datetime-input'>
                                                                                        <input type='date' ref={selectRescheduleDate} />
                                                                                        <select ref={selectRescheduleSlot} className='slot'>
                                                                                            <option value={-1}>Book Slot</option>
                                                                                            <option value={1}>12 AM - 1 AM</option>
                                                                                            <option value={2}>1 AM - 2 AM</option>
                                                                                            <option value={3}>2 AM - 3 AM</option>
                                                                                            <option value={4}>3 AM - 4 AM</option>
                                                                                            <option value={5}>4 AM - 5 AM</option>
                                                                                            <option value={6}>5 AM - 6 AM</option>
                                                                                            <option value={7}>6 AM - 7 AM</option>
                                                                                            <option value={8}>7 AM - 8 AM</option>
                                                                                            <option value={9}>8 AM - 9 AM</option>
                                                                                            <option value={10}>9 AM - 10 AM</option>
                                                                                            <option value={11}>10 AM - 11 AM</option>
                                                                                            <option value={12}>11 AM - 12 AM</option>
                                                                                            <option value={13}>12 AM - 1 PM</option>
                                                                                            <option value={14}>1 PM - 2 PM</option>
                                                                                            <option value={15}>2 PM - 3 PM</option>
                                                                                            <option value={16}>3 PM - 4 PM</option>
                                                                                            <option value={17}>4 PM - 5 PM</option>
                                                                                            <option value={18}>5 PM - 6 PM</option>
                                                                                            <option value={19}>6 PM - 7 PM</option>
                                                                                            <option value={20}>7 PM - 8 PM</option>
                                                                                            <option value={21}>8 PM - 9 PM</option>
                                                                                            <option value={22}>9 PM - 10 PM</option>
                                                                                            <option value={23}>10 PM - 11 PM</option>
                                                                                            <option value={24}>11 PM - 12 PM</option>
                                                                                        </select>
                                                                                    </div>

                                                                                    <div className='submit' onClick={() => {
                                                                                        onRescheduleAppointmentClicked(block._id, close)
                                                                                    }}>Submit</div>
                                                                                </div>
                                                                            }

                                                                            {rescheduleCancelType === "CANCEL" &&
                                                                                <div className='cancel'>
                                                                                    <div>
                                                                                        Appointment Cancellation Reason
                                                                                    </div>
                                                                                    <textarea ref={appointmentCancellationReason} className='cancel-reason' />
                                                                                    <div className='submit' onClick={() => {
                                                                                        onAppointmentCancelClick(block._id, close)
                                                                                    }}>Submit</div>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                )}</Popup>

                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {middleTabSelected === "NOTES" && salesEmployees !== null &&

                                (
                                    <div className='notes'>
                                        <div className='note'>
                                            <div className='heading'>
                                                <div>{CurrentDateTime().split("T")[0]}</div>
                                                <div>{salesEmployees.name}</div>
                                            </div>

                                            {/* <textarea className='note-text' /> */}
                                            <div className='note-text'>
                                                {comments !== null && salesEmployees !== null &&
                                                    comments.map((comment, index) => {

                                                        return (
                                                            <div key={index} className='comment'>
                                                                <span>{comment.comment}</span>
                                                                <span>{getSalesEmployeeName(comment.createdBy)}, {comment.createdOn.split("T")[0]}</span>
                                                            </div>

                                                        )
                                                    })}
                                            </div>
                                        </div>
                                        <div className='comments'>
                                            <div>
                                                Add Comment
                                            </div>
                                            <textarea ref={commentInput} className='comment' />
                                        </div>

                                        <div className='btn'>
                                            <div className='submit' onClick={() => {
                                                onCommentSubmitClick()
                                            }}>Save</div>
                                        </div>
                                    </div>
                                )
                            }

                            {middleTabSelected === "TRANSACTIONS" && (
                                <div className='transactions'>
                                    <div className='title'>All Transactions</div>
                                    {allTransactions !== null && salesEmployees !== null && allTransactions.map((transaction, tIndex) => {
                                        console.log(transaction)
                                        return (
                                            <div key={tIndex} className={`transaction ${transaction.status === "PENDING" ? "pending" : (transaction.status === "PAYMENT_LINK_SEND" ? "payment-link-send" : (transaction.status === "PAYMENT_DONE" ? "payment-done" : ""))}`}>
                                                <div>{transaction.serviceType} for {transaction.patientName}</div>
                                                <div>{getSalesEmployeeName(transaction.transactionInitiatedBy)}: {transaction.transactionInitiationDate.split("T")[0]}</div>
                                                <div className='options'>
                                                    {(transaction.status === "PENDING" || transaction.status === "PAYMENT_LINK_SEND") &&
                                                        <div onClick={() => {
                                                            sendPaymentMessage(transaction.patientName, transaction._id)
                                                        }}>Send Payment Link</div>
                                                    }
                                                    {transaction.status !== "PAYMENT_DONE" &&
                                                        <div onClick={() => {
                                                            setPaymentDone(transaction._id)
                                                        }}>Payment Done</div>
                                                    }
                                                </div>
                                            </div>
                                        )

                                    })}
                                </div>
                            )}

                            {
                                middleTabSelected === "ATTACHMENTS" &&
                                <div className='attachments'>
                                    <div className='title'>Upload Attachments for Patient</div>
                                    <div className='content'>
                                        <div className='upload-section'>

                                            <div className='file-input'>
                                                <input type='file' onChange={(event) => {
                                                    onAttachmentFileChange(event)
                                                }} />

                                                <div className='file-type'>
                                                    <select ref={fileTypeForUpload}>
                                                        <option value="">Select File Type</option>
                                                        <option value="PRESCRIPTION_">Prescription</option>
                                                        <option value="INVOICE_">Invoice</option>
                                                    </select>
                                                </div>
                                                <div className='file-name-for-upload'>
                                                    <input ref={fileNameForUpload} type="text" placeholder='File Name For Upload' />
                                                    <span> *** File extension required</span>
                                                </div>
                                                <div className='submit' onClick={(e) => {
                                                    onFileUpload()
                                                }}>Upload File</div>
                                            </div>

                                            {selectedAttachmentForUpload !== null ? (
                                                <div className='file-selected'>
                                                    <h5>File Details: </h5>
                                                    <p>File Name: {selectedAttachmentForUpload.selectedFile.name}</p>
                                                    <p>File Type: {selectedAttachmentForUpload.selectedFile.type}</p>
                                                    <p>
                                                        Last Modified: {" "}
                                                        {selectedAttachmentForUpload.selectedFile.lastModifiedDate.toDateString()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className='file-not-uploaded'>
                                                    <h5>Choose Before Uploading</h5>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='uploaded-content'>
                                        <div className='title'>Uploaded Files</div>
                                        {patientAttachments !== null &&
                                            patientAttachments.map((pattachment, pindex) => {


                                                return (
                                                    <div className='preview' key={pindex}>
                                                        <img src={pattachment.thumbnailLink} alt={pattachment.name} />
                                                        <div>
                                                            <span>
                                                                {pattachment.name}
                                                            </span>
                                                            <span>
                                                                {pattachment.createdTime.split("T")[0]} {pattachment.createdTime.split("T")[1].split(".")[0]}
                                                            </span>
                                                        </div>

                                                        <div className='send' onClick={() => {
                                                            // sendAttachmentToPatient(pattachment.id)
                                                            downloadFile(pattachment.id)
                                                        }}>Download</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='right'>
                        <div onClick={(e) => {
                            navigate(`/dashboard/patient-book-appointment?pid=+91${patientId}&se=${SE}`)
                        }}>Book Appointment</div>
                        {/* <Popup className='patientinfo' trigger={<div>Book Appointment</div>} modal nested >{(close) => (
                            <div className='book-appointment-popup'>
                                <div className='head'>Book Appointment</div>
                                <div className='inputs'>
                                    <div className='name'>
                                        <div>Patient Name</div>
                                        <select value={patientSelected} onChange={(e) => {
                                            setPatientSelected(e.target.value)
                                        }}>
                                            <option value="" key={-1}>Select Patient</option>
                                            {patientFamily !== null && patientFamily.map((member, index) => (
                                                <option key={index} value={member._id}>{member.name} </option>
                                            ))}

                                            <option value="NEW_PATIENT">New Patient</option>
                                        </select>

                                        {patientSelected === "NEW_PATIENT" &&
                                            <input placeholder='Enter New Patient Name' type="text" onChange={(e) => {
                                                setNewPatientName(e.target.value)
                                            }} />
                                        }
                                    </div>
                                    <div className='cols'>
                                        <div className='left-col'>
                                            <div className='appointment-type'>
                                                <div>Select Appointment Type</div>
                                                <select value={bookAppType} onChange={(e) => { setBookAppType(e.target.value) }}>
                                                    <option value="HOME_CONSULTATION">Home Consultation</option>
                                                    <option value="ONLINE_CONSULTATION">Online Consultation</option>
                                                    <option value="DOCTOR_PROCEDURE">Doctor Procedure</option>
                                                    <option value="NURSING_PROCEDURE">Nursing Procedure</option>
                                                </select>
                                            </div>
                                            <div className='disease-type'>
                                                <div>Select Disease Type</div>
                                                <select value={bookDiseaseType} onChange={(e) => { setBookDiseaseType(e.target.value) }}>
                                                    <option value="FEVER_COLD_COUGH_STOMACHACHE_HEADACHE_CONSTIPATION">Fever Cold Cough Stomachache Headache Constipation</option>
                                                    <option value="VOMITING_LOOSE_MOTION">Vomiting Loose Motion</option>
                                                    <option value="PAIN_INFECTION_INJURY">Pain Infection Injury</option>
                                                    <option value="SPECIALIST">Specialist</option>
                                                    <option value="GENERAL_CHECKUP">General checkup: Reports Review Medical Certificate</option>
                                                    <option value="LOW_HIGH_BP_SUGAR">Low/High BP/Sugar</option>
                                                    <option value="CATHETER_CHANGE_RYLES_TUBE_INSERTION">Catheter Change Ryles Tube Insertion</option>
                                                    <option value="BED_RIDDEN">Bed Ridden</option>
                                                </select>
                                            </div>
                                            <div className='cash-collected'>
                                                <div>Cash To Be Collected</div>
                                                <input type="number" value={bookCashCollected} placeholder='1000' onChange={(e) => { setBookCashCollected(e.target.value) }} />
                                            </div>

                                            <div className='date'>
                                                <div>Date</div>
                                                <input type="date" onChange={(e) => { setBookDate(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className='right-col'>
                                            <div className='opd-type'>
                                                <div>Select OPD Type</div>
                                                <select value={bookOpdType} onChange={(e) => { setBookOpdType(e.target.value) }}>
                                                    <option value="NEW_CONSULTATION">New Consultation</option>
                                                    <option value="FOLLOW_UP_CP">Follow Up - CP</option>
                                                    <option value="NEW_MEMBER_CONSULTATION">New Member Consultation</option>
                                                    <option value="FOLLOW_UP_NP">Follow Up - NP</option>
                                                </select>
                                            </div>
                                            <div className='slot'>
                                                <div>Slot</div>
                                                <select value={bookSlot} onChange={(e) => { setBookSlot(e.target.value) }}>
                                                    <option value={-1}>Book Slot</option>
                                                    <option value={1}>12 AM - 1 AM</option>
                                                    <option value={2}>1 AM - 2 AM</option>
                                                    <option value={3}>2 AM - 3 AM</option>
                                                    <option value={4}>3 AM - 4 AM</option>
                                                    <option value={5}>4 AM - 5 AM</option>
                                                    <option value={6}>5 AM - 6 AM</option>
                                                    <option value={7}>6 AM - 7 AM</option>
                                                    <option value={8}>7 AM - 8 AM</option>
                                                    <option value={9}>8 AM - 9 AM</option>
                                                    <option value={10}>9 AM - 10 AM</option>
                                                    <option value={11}>10 AM - 11 AM</option>
                                                    <option value={12}>11 AM - 12 AM</option>
                                                    <option value={13}>12 AM - 1 PM</option>
                                                    <option value={14}>1 PM - 2 PM</option>
                                                    <option value={15}>2 PM - 3 PM</option>
                                                    <option value={16}>3 PM - 4 PM</option>
                                                    <option value={17}>4 PM - 5 PM</option>
                                                    <option value={18}>5 PM - 6 PM</option>
                                                    <option value={19}>6 PM - 7 PM</option>
                                                    <option value={20}>7 PM - 8 PM</option>
                                                    <option value={21}>8 PM - 9 PM</option>
                                                    <option value={22}>9 PM - 10 PM</option>
                                                    <option value={23}>10 PM - 11 PM</option>
                                                    <option value={24}>11 PM - 12 PM</option>
                                                </select>
                                            </div>
                                            <div className='doctor-type'>
                                                <div>Select Doctor</div>
                                                <select value={bookDoctor} onChange={(e) => { setBookDoctor(e.target.value) }}>
                                                    <option value="" key={-1}>Select doctor</option>
                                                  
                                                    {
                                                        doctors !== null && doctors.map((doc, index) => {
                                                            return(
                                                                <option key={index} value={doc._id}>{doc.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='address'>
                                                <div>Select Address</div>
                                                <select value={addressSelected} onChange={(e) => { setAddressSelected(e.target.value) }}>
                                                    <option key={-2} value="">Select Address</option>
                                                    <option key={-1} value="NEW_ADDRESS">New address</option>
                                                    {patientFamily !== null &&
                                                        patientFamily.map((member, index) => (
                                                            <option key={index} value={member.address}>{member.address}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            {addressSelected === "NEW_ADDRESS" &&
                                                <textarea onChange={(e) => { setNewAddress(e.target.value) }} />
                                            }
                                        </div>
                                    </div>
                                    <div className='submit' onClick={() => {
                                        onBookApppointmentClick(close)
                                    }}>
                                        Submit
                                    </div>

                                    <div className='modal-msg'>{modalMsg}</div>


                                </div>
                            </div>
                        )}</Popup> */}

                        <div>Order Medicine</div>
                        <div>Book Home Care</div>
                        <div>Book Diagnostics</div>
                        <div>Send Payment Link</div>
                        <div>Send Prescription</div>
                    </div>
                </div>
            </div>
        </>
    )
}
