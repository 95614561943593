import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>How to Find Reliable Home Visit Doctors in Your Area:<br/> A Comprehensive Guide</h3>    
    <p>In times of illness, accidents, or mobility issues, ease and accessibility are key elements in receiving important medical care. The <a href="https://www.kynohealth.com/doctor-on-call-home-visit"><strong>doctors home visit </strong></a>services provide an important lifeline by bringing urgent medical care straight to people's homes, avoiding the need for time-consuming travel arrangements. This article walks you through the process of finding reliable doctors' home visit services in your area. Whether you have chronic diseases or need acute treatment, the ability to get medical care from the comfort of your own home can dramatically improve your overall well-being and recovery. Keep reading to learn more.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
