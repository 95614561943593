import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What types of services are available through Kyno Health?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Kyno Health offers a wide range of medical services, including consultations with a doctor, delivery of medications, and diagnostic sample collection from home. You can discuss your specific needs with a doctor during your appointment to determine the best course of treatment.
                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I pay for services through Kyno Health?
</Accordion.Header>
                    <Accordion.Body>
                      <p>You can pay for Kyno Health services through our secure online payment systems and UPI. Our team will work with you to determine the best payment option for your needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Is there a limit to the number or type of medical issues that can be addressed during the consultation?
</Accordion.Header>
                    <Accordion.Body>
                      <p>The doctor with their vast experience will thoroughly examine the patient and answer all questions ranging from nutritional requirements to physical requirements of every patient as per their diagnosis</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I request a specific doctor or type of doctor?
</Accordion.Header>
                    <Accordion.Body>

                    <p>We have full time physicians working exclusively with us, they're going to come & see you. If any specialist doctor is felt to be required post consultation then you'll be referred for the same
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Who are the doctors to come from Kyno Health?</Accordion.Header>
                    <Accordion.Body>

                      <p>All of the doctors who work with Kyno Health are licensed and experienced medical professionals. You can view their profiles and qualifications on our website before booking an appointment.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>What if there is a need of hopsitalization?</Accordion.Header>
                    <Accordion.Body>
                      <p>If any patient requires hospital support then we can refer you to our partner hospitals for best treatment.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can I request follow-up consultations or ongoing care?</Accordion.Header>
                    <Accordion.Body>
                      <p>You can call the patient care number(9953104104) in order to connect with the doctor. If required, doctor will visit the patient again</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index