export const backendURL = "https://www.kynocare.in:8080/"
// export const localBackend = "https://localhost:8080/"
export const localBackend = "https://www.kynocare.in:8080/"

export const chat360sessionapi = "lV6VAEc2.Btj1USjd9wnkDGFNINu1ecBiNTMcE6WS"

export const chat360sessionStartBody = {
    "task_name": "whatsapp_push_notification",
    "extra": "",
    "task_body": [
        {
            "client_number": "919953104104",
            "receiver_number": "918306304683",
            "template_data": {
                "template_id": "a783ae0b-3ba2-4beb-bc08-3a482f516c5a",
                "param_data": {},
                "button_param_data": {}
            }
        }
    ]
}

export const knowlarity_x_api_key = "MwLJEfrDsl5N5iGayBxAFahGrYYUbAJv6Xo5MOCF"
export const knowlarity_authorization_key = "a6e7a4a1-a0ba-4b4f-a585-1066de6ea875"

export default function CurrentDateTime() {
    const currentDate = new Date();
    const indianOffset = 330
    const indiaDateTime = new Date(currentDate.getTime() + (indianOffset * 60000));
    const indiaDateTimeString = indiaDateTime.toISOString();
    return indiaDateTimeString
}


export const slots = [
    "12 AM - 1 AM",
    "1 AM - 2 AM",
    "2 AM - 3 AM",
    "3 AM - 4 AM",
    "4 AM - 5 AM",
    "5 AM - 6 AM",
    "6 AM - 7 AM",
    "7 AM - 8 AM",
    "8 AM - 9 AM",
    "9 AM - 10 AM",
    "10 AM - 11 AM",
    "11 AM - 12 PM",
    "12 PM - 1 PM",
    "1 PM - 2 PM",
    "2 PM - 3 PM",
    "3 PM - 4 PM",
    "4 PM - 5 PM",
    "5 PM - 6 PM",
    "6 PM - 7 PM",
    "7 PM - 8 PM",
    "8 PM - 9 PM",
    "9 PM - 10 PM",
    "10 PM - 11 PM",
    "11 PM - 12 PM",
]

export const patientInfoTempLeadsFilters = [
    {"filter": "PHONE_FILTER", value: ""},
    {"filter": "DATE_FILTER", value1: "", value2: "", value: ""},
    {"filter": "LEAD_STATUS_FILTER", value: ""},
    {"filter": "MARKETING_SOURCE_FILTER", value: ""},
    {"filter": "NAME_FILTER", value: ""},
]
