import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Expert Care for Animal Bites: Your Trusted Doctors</h3>    
    <p className='d-md-none d-lg-none'>Welcome to our dedicated service page focused on providing comprehensive care for animal bites. At Kyno Health, we understand the urgency and concern that arises when dealing with animal bites. Our team of experienced doctors is committed to delivering prompt and effective treatment to ensure the well-being of both you and your furry friends.</p>

<p className='mob-noee'>Welcome to our dedicated service page focused on providing comprehensive care for animal bites. At Kyno Health, we understand the urgency and concern that arises when dealing with animal bites. Our team of experienced doctors is committed to delivering prompt and effective treatment to ensure the well-being of both you and your furry friends.</p>

 <Link className="btn btn-warning " to="tel:+919953104104">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
