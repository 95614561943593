import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import oc1 from '../../utilities/images/overview.png'
import 'bootstrap/dist/css/bootstrap.css'
import "../../styles/overview.scss"
import { useNavigate } from "react-router-dom";

export default function OverviewCarousel(props) {
    const navigate = useNavigate()
    const bookAppointment = () => {
        navigate("/bookAppointment")
    }

    const openInNewTab = (url) => {
        window.open(url, '_black', 'noreferrrer')
    }


    function gtag () { 
        window.dataLayer.push(arguments);
    }

    const gtag_report_conversion = (url) => {
        var callback = function(){
            if(typeof(url) != 'undefined'){
                window.location = url;
            }
        }

        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/KBFFCOiLhpEYEKGEvaUp',
            'event_callback': callback
        });

        return false;
        
    }

    const gtag_report_conversion_wa = (url) => {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          } 
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/WTK8CKiy0ZMYEKGEvaUp',
            'event_callback': callback
        });
        return false;
      }

    return (
        <>
            {/*<Carousel touch={true} fade={false} indicators={false} >

    <Carousel.Item interval={2000}>*/}
                    <div className="overview-item">
                        <div className="overview-content">
                            <div className="title">
                                Doctor at your doorsteps in <span>60 minutes</span>
                            </div>
                            <div className="description">
                            KYNO Health provides expert doctor consultations at home in under an hour. Experience the top-quality medical treatment at the comfort of your home.
                            </div>
                            <div className="links">
                                <div className="book-btn" onClick={() => {
                                    // gtag_report_conversion(window.location)
                                    openInNewTab(`tel:+91${props.phone_number}`)
                                }}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg> Call Us</div>
                                <div className="contact-btn" onClick={() => {
                                    gtag_report_conversion_wa(window.location)
                                    openInNewTab("https://wa.me/9953104104")
                                }}>Connect on WhatsApp</div>
                            </div>
                        </div>
                        <img src={oc1} />
                    </div>
               {/* </Carousel.Item>*/}


                {/* <Carousel.Item interval={2000}>
                    <div className="overview-item">
                        <div className="overview-content">
                            <div className="title">
                                Diagnostic reports and Medicine Delivered at your home.
                            </div>
                            <div className="description">
                                KYNO health is revolutionizing the health care industry by bringing expert care to paople's homes in 60 minutes.
                            </div>
                            <div className="links">
                                <div className="book-btn" onClick={(e) => {bookAppointment()}}>Book an appointment</div>
                                <div className="contact-btn">Contact Us</div>
                            </div>
                        </div>
                        <img src={oc2} />
                    </div>
                </Carousel.Item>


                <Carousel.Item interval={2000}>
                    <div className="overview-item">
                        <div className="overview-content">
                            <div className="title">
                                Expert Doctor consultation
                            </div>
                            <div className="description">
                                KYNO health is revolutionizing the health care industry by bringing expert care to paople's homes in 60 minutes.
                            </div>
                            <div className="links">
                                <div className="book-btn" onClick={(e) => {bookAppointment()}}>Book an appointment</div>
                                <div className="contact-btn">Contact Us</div>
                            </div>
                        </div>
                        <img src={oc3} />
                    </div>
                </Carousel.Item> */}

                {/* <Carousel.Item interval={1000}>
                    <img className="d-block w-100" src={oc2} />

                </Carousel.Item >
                <Carousel.Item interval={1000}>
                    <img className="d-block w-100" src={oc3} />

                </Carousel.Item> */}

            {/*</Carousel>*/}
        </>
    )
}